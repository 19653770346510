import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { ErrorToaster, SuccessToaster } from '../../shared/Toasters/toaster';
import { applyJob } from '../../API/PhotographerApi';
import { useNavigate } from 'react-router-dom';

const SendProposal = ({
  budgetType,
  showModal,
  setShowProposalModal,
  jobId,
}) => {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendProposal = async (data) => {
    const formValue = {
      jobId,
      budgetType,
      hourlyRate: data.hourlyRate || 0,
      budget: data.budget || 0,
      proposalMessage: data.message,
      estimatedHours: 1,
    };

    setStatus('loading');
    try {
      const res = await applyJob(formValue);
      if (res) {
        setShowProposalModal(false);
        SuccessToaster('Successfully Applied for job!');
        setTimeout(() => {
          navigate('/photographer-jobs');
        }, 1000);
      }
    } catch (error) {
      setStatus('rejected');
      ErrorToaster('Something went wrong.');
      setError(error?.message);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowProposalModal(false)}
        size="lg">
        <>
          <Modal.Header closeButton>
            <Modal.Title style={{ textTransform: 'capitalize' }}>
              Send Proposal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center">
            <form noValidate onSubmit={handleSubmit(sendProposal)}>
              <div className="row">
                {budgetType === 'fixed' && (
                  <div className="col-md-6">
                    <div className="mb20">
                      <label
                        htmlFor="budget"
                        className="form-label fw500 dark-color text-capitalize">
                        Your price
                      </label>
                      <input
                        id="budget"
                        {...register('budget', {
                          required: 'Price is required',
                        })}
                        type="number"
                        className="form-control"
                        placeholder={`Please enter your budget`}
                      />
                      {errors.budget && (
                        <p className="text-danger fw500">
                          {errors.budget.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {budgetType === 'hourly' && (
                  <div className="col-md-6">
                    <div className="mb20">
                      <label
                        htmlFor="hourlyRate"
                        className="form-label fw500 dark-color text-capitalize">
                        Your price
                      </label>
                      <input
                        id="hourlyRate"
                        {...register('hourlyRate', {
                          required: 'Price is required',
                        })}
                        type="number"
                        className="form-control"
                        placeholder={`Please enter your hourly rate`}
                      />
                      {errors.hourlyRate && (
                        <p className="text-danger fw500">
                          {errors.hourlyRate.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {/*<div className="col-md-6">*/}
                {/*  <div className="mb20">*/}
                {/*    <label*/}
                {/*      htmlFor="estHours"*/}
                {/*      className="form-label fw500 dark-color text-capitalize">*/}
                {/*      Estimated Hours*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*      id="estHours"*/}
                {/*      {...register('estHours', {*/}
                {/*        required: 'Estimated Hours is required',*/}
                {/*      })}*/}
                {/*      type="number"*/}
                {/*      className="form-control"*/}
                {/*      placeholder={`Please enter your Estimated Hours`}*/}
                {/*    />*/}
                {/*    {errors.mobile && (*/}
                {/*      <p className="text-danger fw500">*/}
                {/*        {errors.mobile.message}*/}
                {/*      </p>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="col-md-12">
                  <div className="mb20">
                    <label
                      htmlFor="coverLetter"
                      className="form-label fw500 dark-color">
                      Cover Letter
                    </label>
                    <textarea
                      className="pt15"
                      {...register('message', {
                        required: 'Cover Letter is required',
                      })}
                      rows="6"
                      placeholder="Message..."></textarea>
                    {errors.message && (
                      <p className="text-danger fw500">
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-start">
                    <Button className="ud-btn btn-thm" type="submit">
                      Submit a Proposal
                      <i className="fal fa-arrow-right-long"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
};

export default SendProposal;
