import { ErrorToaster } from '../shared/Toasters/toaster';

export const logoutUser = async () => {
  try {
    window.localStorage.clear();
    window.location.href = '/';
  } catch (error) {
    ErrorToaster('Something went wrong.');
  }
};
