import React, { useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  // loginUserAsync,
  // resetPasswordAsync,
  responseMessage,
  responseStatus,
  selectAccessToken,
  selectError,
} from '../../authSlice';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import {
  ErrorToaster,
  SuccessToaster,
} from '../../../../shared/Toasters/toaster';
import { setNewPassword } from '../../../../API/AuthApi';
import Loader from '../../../../utils/Loader';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(selectAccessToken);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mID = searchParams.get('mID');
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('accessToken', token);
  }, [token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const resetPassword = async (data) => {
    const formValue = {
      ...data,
      mailId: mID,
    };

    setIsLoading(true);
    try {
      const res = await setNewPassword(formValue);
      if (res.status === 200) {
        SuccessToaster('Password updated successfully');
        setIsLoading(false);
        navigate('/login/photographer');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      ErrorToaster('Something went wrong while resetting password');
    }
  };
  return (
    <>
      <div className="body_content" id="container">
        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp mt-2"
                data-wow-delay="300ms">
                <div className="main-title text-center">
                  <h2 className="title">Reset Password</h2>
                </div>
              </div>
            </div>
            <div
              className="row wow fadeInRight"
              style={{ marginBottom: '200px' }}
              data-wow-delay="300ms">
              <div className="col-xl-6 mx-auto">
                <div className="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  {/* <div className="mb-3">
                    <h4>Welcome Back! It's Great to Have You Here</h4>
                    <div className="mb30">
                      <h4>Login As</h4>
                      <div className="d-flex justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="photographer"
                            id="photographer"
                            value="photographer"
                            checked={userType === 'photographer'}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="photographer">
                            Photographer
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="user"
                            id="user"
                            value="user"
                            checked={userType === 'user'}
                            onChange={handleRadioChange}
                          />
                          <label className="form-check-label" htmlFor="user">
                            Job Poster
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="agency"
                            id="agency"
                            value="agency"
                            checked={userType === 'agency'}
                            onChange={handleRadioChange}
                          />
                          <label className="form-check-label" htmlFor="agency">
                            Agency
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <form noValidate onSubmit={handleSubmit(resetPassword)}>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label fw500 dark-color">
                        New Password
                      </label>
                      <input
                        id="password"
                        {...register('password', {
                          required: 'password is required',
                          pattern: {
                            value:
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                            message: `- at least 8 characters\n
                      - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                      - Can contain special characters`,
                          },
                        })}
                        type="password"
                        className="form-control"
                        placeholder="please enter your new password"
                      />
                      {errors.password && (
                        <p className="text-danger fw500">
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                    <div className="d-grid mb20">
                      <button className="ud-btn btn-thm" type="submit">
                        {isLoading ? (
                          <Loader type="button" />
                        ) : (
                          'Reset Password'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
