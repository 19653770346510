import React, { useState, Suspense } from 'react';
import Loader from '../../../../utils/Loader';

const InsuranceTable = React.lazy(() => import('./InsuranceListing'));

const ManageInsuranceDoc = () => {
  const [activeTab, setActiveTab] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            <button
              className={`nav-link fz16 ${
                !activeTab ? 'active fw500' : 'fw500 fs15'
              }`}
              type="button"
              onClick={() => handleTabClick(false)}>
              Pending
            </button>
            <button
              className={`nav-link fz16 ${
                activeTab ? 'active fw500 ps-0' : 'fw500'
              }`}
              type="button"
              onClick={() => handleTabClick(true)}>
              Approved
            </button>
          </div>
        </nav>
        <div className="tab-content">
          {!activeTab && (
            <Suspense fallback={<Loader />}>
              <InsuranceTable insuranceStatus="unverified" />
            </Suspense>
          )}
          {activeTab && (
            <Suspense fallback={<Loader />}>
              <InsuranceTable insuranceStatus="verified" />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageInsuranceDoc;
