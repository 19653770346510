import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../common/getUserInfo';

const RedirectIfLoggedIn = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === 'photographer' || userInfo.userType === 'agency')
    ) {
      navigate('/jobs');
    }
    if (userInfo && userInfo.userType === 'user') {
      navigate('/photographers');
    }
    if (userInfo && userInfo.userType === 'admin') {
      navigate('/manage-photographers');
    }
  }, [location, navigate, userInfo]);
  return children;
};

export default RedirectIfLoggedIn;
