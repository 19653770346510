import { Navigate } from 'react-router-dom';
import { getUserInfo } from '../../common/getUserInfo';

function PhotographerPrivateRoute({ children }) {
  const userInfo = getUserInfo();
  if (
    !userInfo ||
    (userInfo && !['photographer', 'agency'].includes(userInfo.userType))
  ) {
    return <Navigate to="/" replace={true} />;
  }
  return children;
}

export default PhotographerPrivateRoute;
