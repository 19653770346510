import React from 'react';
import { privacyPolicy } from '../../dummyData';

const PrivacyPolicy = () => {
  return (
    <div className="body_content">
      <section className="our-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-title">
                <h2>Privacy And Policy</h2>
                <p className="text">
                  Welcome to Topshot Photographer! This Privacy Policy explains
                  how we collect, use, and disclose personal information from
                  users of our platform. By accessing or using our platform, you
                  agree to the terms outlined in this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-2">
              <div className="terms_condition_widget mb30-sm">
                <div className="widget_list">
                  <nav>
                    <div
                      className="nav nav-tabs text-start"
                      id="nav-tab"
                      role="tablist">
                      <button
                        className="nav-link text-start"
                        id="nav-accountpayment-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-accountpayment"
                        type="button"
                        role="tab"
                        aria-controls="nav-accountpayment"
                        aria-selected="true">
                        Account & Payments
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
              <div className="terms_condition_grid text-start">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab">
                    {privacyPolicy?.map((item) => (
                      <div className="grids mb30 mb40-md" key={item?.id}>
                        <h4 className="title">{`${item?.id}. ${item?.title}`}</h4>
                        <p className="mb25 text fz16">
                          {item.description
                            .split('topshotconnect@gmail.com')
                            .map((part, index) => (
                              <span key={index}>
                                {index === 0 && part}
                                {index === 1 && (
                                  <span
                                    style={{
                                      color: 'green',
                                      textDecoration: 'underline',
                                    }}>
                                    topshotconnect@gmail.com
                                  </span>
                                )}
                                {index > 1 && part}
                              </span>
                            ))}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
