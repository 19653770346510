import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getPlans } from "../../API/AuthApi";
import { ErrorToaster, SuccessToaster } from "../../shared/Toasters/toaster";
import { Status } from "../../constants/constant";
import Loader from "../../utils/Loader";
import {
  createCustomer,
  createSubscription,
  updateSubscription,
} from "../../API/StripeApi";
import { useStripe } from "@stripe/react-stripe-js";
import { myDetail } from "../../API/UserApi";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../features/auth/authSlice";

const SubscriptionPlans = () => {
  const stripe = useStripe();
  const token = useSelector(selectAccessToken);
  const [show, setShow] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [plans, setPlans] = useState();
  const [userInfo, setUserInfo] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [billingInterval, setBillingInterval] = useState("month");
  const navigate = useNavigate();

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      setUserInfo(data);
      setBillingInterval(data?.planInterval || "month");
      localStorage.setItem("currentUser", JSON.stringify(data));
      setStatus(Status.IDLE);
    } catch (error) {
      console.error("Error while getting user details", error);
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  useEffect(() => {
    if (token) myDetails();
  }, [token]);

  const handleToggle = () => {
    setShow((prev) => !prev);
    setBillingInterval((prev) => (prev === "month" ? "year" : "month"));
  };

  const getAllPlans = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getPlans();
      setPlans(res || {});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const handleSubscriptionClick = async (planName, planInterval) => {
    setLoadingPlan(planName);

    const handleCheckout = async (sessionId) => {
      if (!stripe) return;
      await stripe.redirectToCheckout({ sessionId });
    };

    const createOrUpdateSubscription = async (formData) => {
      if (userInfo.subscriptionId) {
        formData.subscriptionId = userInfo.subscriptionId;
        return await updateSubscription(formData);
      }
      return await createSubscription(formData);
    };

    try {
      if (!userInfo?.stripeId) {
        const customerCreated = await createCustomer();
        if (!customerCreated) throw new Error("Customer creation failed");

        const formData = {
          tier: planName.toLowerCase(),
          interval: planInterval,
        };
        const sessionId = await createSubscription(formData);
        setLoadingPlan(null);
        await handleCheckout(sessionId);
        myDetails();
      } else {
        const formData = {
          tier: planName.toLowerCase(),
          interval: planInterval,
        };
        const response = await createOrUpdateSubscription(formData);
        setLoadingPlan(null);

        if (response?.sessionId) {
          await handleCheckout(response.sessionId);
        }

        myDetails();
      }

      SuccessToaster("Subscription updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Subscription error", error);
      setLoadingPlan(null);
      ErrorToaster("Something went wrong!");
    }
  };

  return (
    <section className="our-pricing pb90">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-auto wow fadeInUp">
            <div className="main-title text-center mb30">
              <h2 className="title">Membership Plans</h2>
            </div>
          </div>
        </div>
        <div className="row wow fadeInUp" data-wow-delay="200ms">
          <div className="col-lg-12">
            <div className="pricing_packages_top d-flex align-items-center justify-content-center mb60">
              <div className="toggle-btn">
                <span className="pricing_save1 dark-color ff-heading">
                  Billed Yearly
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="checkbox"
                    defaultChecked={billingInterval}
                    onClick={handleToggle}
                  />
                  <span className="pricing_table_switch_slide round"></span>
                </label>
                <span className="pricing_save2 dark-color ff-heading">
                  Billed Monthly
                </span>
              </div>
            </div>
          </div>
        </div>
        {status === Status.PENDING ? (
          <div className="text-center mt-5">
            <Loader />
          </div>
        ) : status === Status.REJECTED ? (
          <div className="text-center mt-5">
            <p>{error}</p>
          </div>
        ) : (
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            {plans &&
              Object.keys(plans).map((planKey) => {
                const plan = plans[planKey];
                return (
                  <div className="col-sm-6 col-xl-3" key={plan.id}>
                    <div
                      className="pricing_packages at-home2 text-center bdrs16"
                      style={{
                        border: "1px solid grey",
                        backgroundColor:
                          (userInfo?.userPlanType === planKey &&
                            userInfo?.planInterval === billingInterval) ||
                          userInfo?.userPlanType === planKey
                            ? "#FFEDE8"
                            : "transparent",
                      }}
                    >
                      <div className="heading mb10">
                        <h1
                          className={`text2 ${
                            billingInterval === "month" ? "" : "d-none"
                          }`}
                        >
                          ${plan.amount} <small>/ monthly</small>
                        </h1>
                        <h1
                          className={`text1 ${
                            billingInterval === "year" ? "" : "d-none"
                          }`}
                        >
                          ${plan.yearly} <small>/ yearly</small>
                        </h1>
                        <h4 className="package_title mt-2">{planKey}</h4>
                      </div>
                      <div className="details">
                        <div className="pricing-list mb40">
                          <ul className="px-0">
                            <li>
                              Apply for up to {plan?.jobLimit} jobs per month.
                            </li>
                            <li>
                              Choose {plan?.categoryLimit} categories to display
                              your work.
                            </li>
                            <li>
                              Feature up to {plan?.pictureLimit} photos in each
                              selected category.
                            </li>
                            {/* <li>{plan?.isSearchable && 'Searchable'}</li> */}
                          </ul>
                        </div>
                        <div className="d-grid">
                          {userInfo ? (
                            (userInfo?.userPlanType === planKey &&
                              userInfo?.planInterval === billingInterval) ||
                            userInfo?.userPlanType === planKey ? (
                              <button className="ud-btn btn-light-thm" disabled>
                                Active Plan
                              </button>
                            ) : (
                              <button
                                className="ud-btn btn-light-thm"
                                onClick={() =>
                                  handleSubscriptionClick(
                                    planKey,
                                    billingInterval
                                  )
                                }
                                disabled={loadingPlan === planKey}
                              >
                                {loadingPlan === planKey
                                  ? "Loading..."
                                  : "Subscribe"}
                              </button>
                            )
                          ) : (
                            <Link
                              to="/signup/photographer"
                              className="ud-btn btn-light-thm"
                            >
                              Sign Up
                              <i className="fal fa-arrow-right-long"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </section>
  );
};

export default SubscriptionPlans;
