import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  loginUser,
  userSignup,
  signOut,
  verifyOtp,
  resetPassword,
  forgetPassword,
  googleLogin,
  mobileLogin,
  mobileVerifyOtp,
} from './authApi';

const initialState = {
  accessToken: null, // this should only contain user identity => 'id'/'role'
  status: null,
  error: null,
  isLoading: false,
  message: null,
};

export const userSignupAsync = createAsyncThunk(
  'auth/createUser',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await userSignup(formValue);
      return response.message;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const GoogleLoginAsync = createAsyncThunk(
  'auth/GoogleLogin',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await googleLogin(formValue);
      localStorage.setItem('accessToken', response?.token);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const loginUserAsync = createAsyncThunk(
  'auth/loginUser',
  async (loginInfo, { rejectWithValue }) => {
    try {
      const response = await loginUser(loginInfo);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const verifyOtpAsync = createAsyncThunk(
  'auth/sign-out',
  async (values) => {
    try {
      const response = await verifyOtp(values);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const mobileLoginAsync = createAsyncThunk(
  'auth/mobileLogin',
  async (loginInfo, { rejectWithValue }) => {
    try {
      const response = await mobileLogin(loginInfo);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const mobileVerifyOtpAsync = createAsyncThunk(
  'auth/mobileVerifyOtp',
  async (values) => {
    try {
      const response = await mobileVerifyOtp(values);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const forgetPasswordAsync = createAsyncThunk(
  'auth/forget-password',
  async (values, { rejectWithValue }) => {
    try {
      const response = await forgetPassword(values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/reset-password',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await resetPassword(formValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignupAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(userSignupAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(userSignupAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'pending';
        state.error = null;
        state.isLoading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.accessToken = action.payload.token;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(GoogleLoginAsync.pending, (state) => {
        state.status = 'pending';
        state.error = null;
        state.isLoading = true;
      })
      .addCase(GoogleLoginAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload.message;
        state.accessToken = action.payload.token;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(GoogleLoginAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(verifyOtpAsync.pending, (state) => {
        state.status = 'pending';
        state.error = false;
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload.message;
        state.accessToken = action.payload.token;
        state.error = false;
      })
      .addCase(verifyOtpAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(mobileLoginAsync.pending, (state) => {
        state.status = 'pending';
        state.error = null;
        state.isLoading = true;
      })
      .addCase(mobileLoginAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload.message;
        state.accessToken = action.payload.token;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(mobileLoginAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(mobileVerifyOtpAsync.pending, (state) => {
        state.status = 'pending';
        state.error = false;
      })
      .addCase(mobileVerifyOtpAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload.message;
        state.accessToken = action.payload.token;
        state.error = false;
      })
      .addCase(mobileVerifyOtpAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(forgetPasswordAsync.pending, (state) => {
        state.status = 'pending';
        state.error = false;
        state.message = null;
      })
      .addCase(forgetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = 'Reset Password Link Has Been Sent To Your Email';
        state.error = false;
      })
      .addCase(forgetPasswordAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = 'pending';
        state.error = false;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload.message;
        state.error = false;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      });
  },
});

export const { clearStatus, clearError, clearMessage } = authSlice.actions;
export const selectAccessToken = (state) => state.auth.accessToken;
export const responseMessage = (state) => state.auth.message;
export const responseStatus = (state) => state.auth.status;
export const selectError = (state) => state.auth.error;
export const isLoadingStatus = (state) => state.auth.isLoading;

export default authSlice.reducer;
