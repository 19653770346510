import { axiosWithToken, axiosWithoutToken } from '../../common/axiosConfig';
import {
  JobListURL,
  JobDetailURL,
  CreateJobURL,
} from '../../constants/apiConstants';

export function createJob(formValue) {
  return new Promise(async (resolve, reject) => {
    const response = await axiosWithToken.post(CreateJobURL, formValue);
    if (response.status === 200) {
      const data = response.data;
      resolve({ data });
    } else {
      const error = response;
      reject(error);
    }
  });
}

export function jobList() {
  return new Promise(async (resolve, reject) => {
    const response = await axiosWithoutToken.post(JobListURL, {});
    if (response.status === 200) {
      const data = response.data;
      resolve({ data });
    } else {
      const error = response;
      reject(error);
    }
  });
}

export function jobDetail(jobId) {
  return new Promise(async (resolve, reject) => {
    const response = await axiosWithToken.post(JobDetailURL, { jobId });
    if (response.status === 200) {
      const data = response.data;
      resolve({ data });
    } else {
      const error = response;
      reject(error);
    }
  });
}
