import * as _ from 'lodash';

import { axiosWithToken, axiosWithoutToken } from '../common/axiosConfig';
import {
  CreateJobURL,
  DeleteInviteURL,
  InvitePhotographerURL,
  JobDetailURL,
  JobListURL,
  JobListWithoutInviteURL,
  JobProposalStatusUpdateURL,
  JobStatusUpdateURL,
  MyJobDetailsURL,
  MyJobListURL,
  ProposalListURL,
  UpdateInviteURL,
} from '../constants/apiConstants';

export async function createJob(values) {
  return axiosWithToken.post(CreateJobURL, values).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export function jobList(filterData) {
  return axiosWithoutToken.post(JobListURL, filterData).then((res) => {
    return _.get(res, 'data.jobs', {});
  });
}

export function jobDetails(value) {
  return axiosWithToken.post(JobDetailURL, value).then((res) => {
    return _.get(res, 'data.details', {});
  });
}

export async function myJobList(jobStatus) {
  return axiosWithToken.post(MyJobListURL, { jobStatus }).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function myJobDetails(jobId) {
  return axiosWithToken.post(MyJobDetailsURL, { jobId }).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateJobStatus(value) {
  return axiosWithToken.post(JobStatusUpdateURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateJobProposalStatus(value) {
  return axiosWithToken.post(JobProposalStatusUpdateURL, value).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function jobProposalList(value) {
  return axiosWithToken.post(ProposalListURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function jobListWithoutInvite(value) {
  return axiosWithToken.post(JobListWithoutInviteURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function invitePhotographer(value) {
  return axiosWithToken.post(InvitePhotographerURL, value).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function deleteJobInvite(value) {
  return axiosWithToken.post(DeleteInviteURL, value).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function updateJobInviteStatus(value) {
  return axiosWithToken.post(UpdateInviteURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}
