import ErrorBoundary from './Errorboundary';
import React, { Suspense, lazy } from 'react';

const TalentSection = lazy(() => import('./TalentSection'));
const FindPhotographerSection = lazy(() => import('./FindPhotographers'));
const NeedSomethingSection = lazy(() => import('./NeedSomethingSection'));
const InspiringWorkSection = lazy(() => import('./InspiringWorkSection'));
const HeroSection = lazy(() => import('./HeroSection'));
const OurLatestJobs = lazy(() => import('./OurLatestJobs'));

const Home = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <ErrorBoundary>
        <Suspense fallback>
          <HeroSection />
        </Suspense>
        {/* <!-- Browse talent by category --> */}
        <Suspense fallback>
          <TalentSection />
        </Suspense>
        <Suspense fallback>
          <FindPhotographerSection />
        </Suspense>
        {/* <!-- Need something --> */}
        <Suspense fallback>
          <NeedSomethingSection />
        </Suspense>
        {/* <!-- Our Latest Jobs --> */}
        <Suspense fallback>
          <OurLatestJobs />
        </Suspense>
        {/* <!-- Inspiring work --> */}
        <Suspense fallback>
          <InspiringWorkSection />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default Home;
