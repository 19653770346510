import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAccessToken } from '../authSlice';
import { getUserInfo } from '../../../common/getUserInfo';

function Protected({ children }) {
  const token = useSelector(selectAccessToken);
  const userInfo = getUserInfo();
  if (!userInfo) {
    return <Navigate to="/" replace={true} />;
  }
  return children;
}

export default Protected;
