import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { contactUs } from '../../API/AuthApi';
import { Status } from '../../constants/constant';
import { ErrorToaster, SuccessToaster } from '../../shared/Toasters/toaster';

const ContactUs = () => {
  const [status, setStatus] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const sendQuery = async (data) => {
    setStatus(Status.PENDING);
    try {
      const res = await contactUs(data);
      if (res) {
        SuccessToaster('Query Sent Successfully!');
        setStatus(Status.IDLE);
        reset();
      }
    } catch (error) {
      console.error('error while send query', error);
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };
  return (
    <div>
      <section className="breadcumb-section wow fadeInUp mt40">
        <div className="cta-commmon-v1 cta-banner bgc-thm2 mx-auto maxw1700 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
          <img
            className="left-top-img wow zoomIn"
            src="https://creativelayers.net/themes/freeio-html/images/vector-img/left-top.png"
            alt=""
          />
          <img
            className="right-bottom-img wow zoomIn"
            src="https://creativelayers.net/themes/freeio-html/images/vector-img/right-bottom.png"
            alt=""
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-5">
                <div
                  className="position-relative wow fadeInUp"
                  data-wow-delay="300ms">
                  <h2 className="text-white">Contact us</h2>
                  <p className="text mb0 text-white fz16">
                    We'd love to talk about how we can help you. Reach out to us
                    at any time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-6">
              <div className="position-relative mt40">
                <div className="main-title">
                  <h4 className="form-title mb25">Keep In Touch With Us.</h4>
                  <p className="text fz16">
                    We’re here to help you every step of the way. Whether you're
                    a photographer or a client, we’d love to hear from you.
                  </p>
                </div>
                <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                  <div className="icon flex-shrink-0">
                    <span className="flaticon-mail"></span>
                  </div>
                  <div className="details">
                    <h5
                      className="title"
                      style={{ fontSize: '24px !important' }}>
                      Email
                    </h5>
                    <p className="mb-0 text">
                      <a href="mailto:topshotconnect@gmail.com">
                        topshotconnect@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-page-form default-box-shadow1 bdrs8 bdr1 p50 mb30-md bgc-white">
                <h4 className="form-title mb25">Tell us about yourself</h4>
                <p className="text mb30 fz16">
                  Have questions, feedback, or just want to say hello? Contact
                  us using the form below.
                </p>
                <form
                  noValidate
                  onSubmit={handleSubmit(sendQuery)}
                  className="form-style1">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb20">
                        <label
                          className="heading-color ff-heading fw500 mb10 fz16"
                          htmlFor="">
                          Name
                        </label>
                        <input
                          id="name"
                          {...register('name', {
                            required: 'Name is required',
                          })}
                          type="text"
                          className="form-control"
                          placeholder="Please Enter Your Name"
                          autoComplete="off"
                        />
                        {errors.name && (
                          <p style={{ color: 'red' }}>{errors.name.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb20">
                        <label
                          className="heading-color ff-heading fw500 mb10 fz16"
                          htmlFor="">
                          Email
                        </label>
                        <input
                          type="email"
                          {...register('email', {
                            required: 'Email is required',
                          })}
                          className="form-control"
                          placeholder="Please Enter Your Email"
                        />
                        {errors.email && (
                          <p style={{ color: 'red' }}>{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb20">
                        <label
                          className="heading-color ff-heading fw500 mb10 fz16"
                          htmlFor="">
                          Message
                        </label>
                        <textarea
                          name="message"
                          id=""
                          {...register('message', {
                            required: 'Message is required',
                          })}
                          cols="30"
                          rows="6"
                          placeholder="Description"></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="">
                        <button className="ud-btn btn-thm">
                          Send Message
                          <i className="fal fa-arrow-right-long"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
