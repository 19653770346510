import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = ({ type, isUploading }) => {
  const loaderSize = type === ('button' || 'img') || isUploading ? 20 : 50;
  const loaderStyles =
    type === ('button' || 'img') || isUploading
      ? {}
      : {
          marginLeft: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '50vh',
        };
  return (
    <div style={loaderStyles}>
      <ClipLoader color="#5BBB7B" size={loaderSize} />
      {isUploading ? 'Uploading...' : 'Loading...'}
    </div>
  );
};

export default Loader;
