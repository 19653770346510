import * as _ from 'lodash';

import { axiosWithToken, axiosWithoutToken } from '../common/axiosConfig';
import {
  VerifyOtpURL,
  ForgetPasswordURL,
  ResetPasswordURL,
  PlansURL,
  MobileSignInURL,
  MobileSignInVerifyURL,
  ContactUsUrl,
} from '../constants/apiConstants';

export function verifyOtp(values) {
  return axiosWithoutToken.post(VerifyOtpURL, values).then((res) => {
    return _.get(res, 'data.token', {});
  });
}

export function mobileLogin(values) {
  return axiosWithoutToken.post(MobileSignInURL, values).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export function mobileVerifyOtp(values) {
  return axiosWithoutToken.post(MobileSignInVerifyURL, values).then((res) => {
    return _.get(res, 'data.token', {});
  });
}

export function forgotPassword(values) {
  return axiosWithoutToken.post(ForgetPasswordURL, values).then((res) => {
    return _.get(res, 'data', {});
  });
}

export function setNewPassword(formValue) {
  return axiosWithoutToken.post(ResetPasswordURL, formValue).then((res) => {
    return _.get(res, 'data', {});
  });
}

export function contactUs(formValue) {
  return axiosWithoutToken.post(ContactUsUrl, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function getPlans() {
  return axiosWithToken.get(PlansURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}
