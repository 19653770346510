// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown:hover > .dropdown-menu {
  display: block;
  min-width: 8rem;
  border-radius: 0.55rem;
  margin-top: 2px;
}

.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/* Navbar.css */
.header-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust this value as needed */
  /* Add any other styles you need for your navbar */
}

/* Adjust the body padding or margin to accommodate the fixed navbar */
body {
  padding-top: 70px;
}

.custom-dropdown-item {
  padding: 0px 15px; /* Adjust padding as needed */
  margin: 0; /* Remove margin */
}

/* Responsive padding for smaller screens */
@media (max-width: 1168px) {
  body {
    padding-top: 0;
  }
}

/* Additional responsive adjustments if needed */
@media (max-width: 576px) {
  body {
    padding-top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,6CAA6C;EAC7C,oBAAoB;AACtB;;AAEA,eAAe;AACf;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa,EAAE,gCAAgC;EAC/C,kDAAkD;AACpD;;AAEA,sEAAsE;AACtE;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB,EAAE,6BAA6B;EAChD,SAAS,EAAE,kBAAkB;AAC/B;;AAEA,2CAA2C;AAC3C;EACE;IACE,cAAc;EAChB;AACF;;AAEA,gDAAgD;AAChD;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".dropdown:hover > .dropdown-menu {\n  display: block;\n  min-width: 8rem;\n  border-radius: 0.55rem;\n  margin-top: 2px;\n}\n\n.dropdown > .dropdown-toggle:active {\n  /*Without this, clicking will make it sticky*/\n  pointer-events: none;\n}\n\n/* Navbar.css */\n.header-nav {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 1000; /* Adjust this value as needed */\n  /* Add any other styles you need for your navbar */\n}\n\n/* Adjust the body padding or margin to accommodate the fixed navbar */\nbody {\n  padding-top: 70px;\n}\n\n.custom-dropdown-item {\n  padding: 0px 15px; /* Adjust padding as needed */\n  margin: 0; /* Remove margin */\n}\n\n/* Responsive padding for smaller screens */\n@media (max-width: 1168px) {\n  body {\n    padding-top: 0;\n  }\n}\n\n/* Additional responsive adjustments if needed */\n@media (max-width: 576px) {\n  body {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
