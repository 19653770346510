// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { addPortfolio, getUserPortfolios } from './photographerApi';

// const initialState = {
//   status: null,
//   error: null,
//   isLoading: null,
//   message: null,
//   userPortfolios: [],
// };

// export const addPortfolioAsync = createAsyncThunk(
//   'user/addPortfolio',
//   async (formData, { rejectWithValue }) => {
//     console.log('formData', formData);
//     try {
//       const response = await addPortfolio(formData);
//       console.log('re', response);
//       return response.message;
//     } catch (error) {
//       console.log('error', error);
//       return rejectWithValue(error?.response?.data.message);
//     }
//   }
// );

// export const getUserPortfoliosAsync = createAsyncThunk(
//   'user/getUserPortfolios',
//   async ({ rejectWithValue }) => {
//     try {
//       const response = await getUserPortfolios();
//       console.log('re', response);
//       return response.data;
//     } catch (error) {
//       console.log('error', error);
//       return rejectWithValue(error?.response?.data.message);
//     }
//   }
// );

// export const photographerSlice = createSlice({
//   name: 'photographer',
//   initialState,
//   reducers: {
//     clearStatus: (state) => {
//       state.status = null;
//     },
//     clearError: (state) => {
//       state.error = null;
//     },
//     clearMessage: (state) => {
//       state.message = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(addPortfolioAsync.pending, (state) => {
//         state.status = 'pending';
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(addPortfolioAsync.fulfilled, (state, action) => {
//         state.status = 'success';
//         state.message = action.payload;
//         state.isLoading = false;
//         state.error = null;
//       })
//       .addCase(addPortfolioAsync.rejected, (state, action) => {
//         state.status = 'rejected';
//         state.isLoading = false;
//         state.error = action.payload;
//       })
//       .addCase(getUserPortfoliosAsync.pending, (state) => {
//         state.status = 'pending';
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(getUserPortfoliosAsync.fulfilled, (state, action) => {
//         state.status = 'success';
//         state.userPortfolios = action.payload;
//         state.isLoading = false;
//         state.error = null;
//       })
//       .addCase(getUserPortfoliosAsync.rejected, (state, action) => {
//         state.status = 'rejected';
//         state.isLoading = false;
//         state.error = action.payload;
//       });
//   },
// });
// export const { clearStatus, clearError, clearMessage } =
//   photographerSlice.actions;
// export const selectStatus = (state) => state.photographer.status;
// export const selectError = (state) => state.photographer.error;
// export const selectMessage = (state) => state.photographer.message;
// export const selectUserPortfolios = (state) =>
//   state.photographer.userPortfolios;

// export default photographerSlice.reducer;
