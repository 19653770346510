import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAccessToken } from '../../features/auth/authSlice';
import { getUserInfo } from '../../common/getUserInfo';

const AdminProtectedRoute = ({ children }) => {
  const token = useSelector(selectAccessToken);
  const userInfo = getUserInfo();

  if (token && userInfo && userInfo.userType === 'admin') {
    return children;
  }
  return <Navigate to="/" replace={true} />;
};

export default AdminProtectedRoute;
