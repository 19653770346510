import React, { useState } from 'react';
import MapComponent from '../../MapComponent/MapComponent';
import { useForm } from 'react-hook-form';

const Location = ({ setFilterLocation, filterType }) => {
  const [show, setShow] = useState(true);
  const { register, setValue } = useForm();

  return (
    <div className="card mb20 pb5">
      <div className={`${show ? 'active' : ''}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}>
            Location
          </button>
        </h4>
      </div>
      <div
        id="collapse2"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="heading2"
        data-parent="#accordionExample">
        <div className="card-body card-body px-0 pt-0">
          <div className="search_area mb15">
            <MapComponent
              name="filterLocation"
              register={register}
              setValue={setValue}
              setFilterLocation={setFilterLocation}
              placeholder={
                filterType === 'jobs'
                  ? 'Enter Location (USA Only)'
                  : 'Where are you looking to hire?'
              }
            />
            <label>
              <span className="flaticon-loupe"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
