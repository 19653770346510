import { useState } from 'react';
import { number, string } from 'prop-types';
import ArrowDown from '../utils/Icons/ArrowDown';
import ArrowUp from '../utils/Icons/ArrowUp';

export const AdditionalInfoRenderer = ({ text, maxLength }) => {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const toggleFullTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  if (!text) {
    return <span>-</span>;
  }

  const displayText = isFullTextVisible
    ? text
    : text.length > maxLength
    ? text.slice(0, maxLength)
    : text;

  return (
    <span className="me-2 cursor-pointer">
      <span className="text-break">{displayText}</span>{' '}
      {text.length > maxLength && (
        <span
          className="toggle-link text-hover view-more-btn font-size12 font-weight-700"
          onClick={toggleFullTextVisibility}
          style={{ color: '#1e90ff' }} // Different colors for "View more" and "View less"
        >
          <span className="d-inline-flex">
            {isFullTextVisible ? (
              <>
                View less <ArrowUp fill="#fff" stroke="#103960" />
              </>
            ) : (
              <>
                ...View more <ArrowDown fill="#fff" stroke="#103960" />
              </>
            )}
          </span>
        </span>
      )}
    </span>
  );
};

AdditionalInfoRenderer.propTypes = {
  text: string,
  maxLength: number,
};
