import React, { useEffect, useState } from 'react';
import { Status, UserTypes } from '../../../../../constants/constant';
import { updateProfileStatus, userList } from '../../../../../API/AdminApi';
import { ErrorToaster } from '../../../../../shared/Toasters/toaster';
import Loader from '../../../../../utils/Loader';
import { EmptyComponent } from '../../../../../shared/Empty/Empty';
import { formatDate } from '../../../../../utils/Dates';
import EyeIcon from '../../../../../utils/Icons/EyeIcon';
import PenIcon from '../../../../../utils/Icons/PenIcon';
import { Link } from 'react-router-dom';
import { StatusModal } from '../../../../../shared/Modal/StatusModal';

const InComplete = ({ userStatus }) => {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [show, setShow] = useState(false);

  const getUserList = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await userList({
        userType: UserTypes.PHOTOGRAPHER,
        status: userStatus,
      });
      setUsers(res);
      setStatus(Status.IDLE);
    } catch (error) {
      console.error('error', error);
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleStatusChange = async (userId) => {
    setShow(true);
    setCurrentUser(userId);
  };

  return (
    <>
      {status === Status.PENDING ? (
        <Loader />
      ) : (
        <>
          <StatusModal show={show} setShow={setShow} userId={currentUser} />
          {users?.length > 0 ? (
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="ps-widget bgc-white bdrs4 overflow-hidden position-relative"
                  style={{ marginTop: '-20px' }}>
                  <div className="packages_table table-responsive">
                    <table className="table-style3 table at-savesearch">
                      <thead className="t-head">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Plan</th>
                          <th scope="col">CreatedAt</th>
                          <th scope="col">Social Media</th>
                          <th scope="col">Status</th>
                          <th scope="col">IsActive</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="t-body">
                        {users?.map((user) => (
                          <tr key={user?._id}>
                            <th scope="row">
                              <div
                                className="freelancer-style1 p-0 mb-0 box-shadow-none"
                                style={{ marginLeft: '-30px' }}>
                                <div className="d-lg-flex align-items-lg-center">
                                  <div className="thumb w60 position-relative rounded-circle mb15-md">
                                    <img
                                      className="rounded-circle mx-auto"
                                      src={
                                        user?.profilePicture ||
                                        'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg'
                                      }
                                      alt=""
                                      style={{
                                        width: '60px',
                                        height: '60px',
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                      }}
                                    />
                                    {/* <span className="online-badge2"></span> */}
                                  </div>
                                  <div className="details ml15 ml0-md mb15-md">
                                    <h5 className="title mb-2">
                                      {user?.fullName}
                                    </h5>
                                    <h6 className="mb-0 text-thm">
                                      {user?.email}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <td className="vam">
                              <span className="fz15 fw400">
                                {user?.userPlanType}
                              </span>
                            </td>
                            <td className="vam">
                              <span>
                                {formatDate(
                                  user?.creationDate ||
                                    '2023-12-04T19:55:25.912Z'
                                )}
                              </span>
                              <br />
                              {/* <span className="fz14 fw400">April 25, 2011</span> */}
                            </td>
                            <td className="vam">
                              <span
                                className="pending-style style1"
                                style={{
                                  background: user?.shareConsent
                                    ? 'rgb(155 231 181)'
                                    : 'rgb(241 16 16)',
                                  color: '#ffffff',
                                }}>
                                {user?.shareConsent ? 'Enabled' : 'Disabled'}
                              </span>
                            </td>
                            <td className="vam">
                              <span className="pending-style style6 text-capitalize">
                                {user?.profileStatus ===
                                Status.PENDING_FOR_REVIEW
                                  ? 'In-Review'
                                  : user?.profileStatus}
                              </span>
                            </td>
                            <td className="vam">
                              <span
                                className="pending-style style6"
                                style={{
                                  background: user?.isDisabled
                                    ? 'rgb(241 165 16)'
                                    : 'rgb(155 231 181)',
                                  color: '#ffffff',
                                }}>
                                {user?.isDisabled ? 'InActive' : 'Active'}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="icon me-2"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  onClick={() => handleStatusChange(user?._id)}
                                  title="Edit">
                                  <PenIcon />
                                </div>
                                <Link
                                  to={`/manage-photographers/${user?._id}`}
                                  className="icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="View">
                                  <EyeIcon />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt90">
              <EmptyComponent
                description="No Data."
                // type={true}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InComplete;
