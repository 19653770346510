// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.google-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the start */
  padding: 0px 5px; /* Adjust padding if necessary */
  border: none;
  border-radius: 5px;
  background-color: #4285f4; /* Google Blue */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-btn svg {
  margin-right: 10px; /* Add some space between the icon and text */
}

.google-btn:hover {
  background-color: #3b78e7; /* Slightly darker shade of Google Blue */
}

.google-btn span {
  margin: 0 auto; /* Center the text within the button */
  position: absolute; /* Use absolute positioning */
  left: 50%; /* Center based on the left edge */
  transform: translateX(-50%); /* Adjust position to be truly centered */
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/Icons/GoogleButton/GoogleButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B,EAAE,+BAA+B;EAC5D,gBAAgB,EAAE,gCAAgC;EAClD,YAAY;EACZ,kBAAkB;EAClB,yBAAyB,EAAE,gBAAgB;EAC3C,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,kBAAkB,EAAE,6CAA6C;AACnE;;AAEA;EACE,yBAAyB,EAAE,yCAAyC;AACtE;;AAEA;EACE,cAAc,EAAE,sCAAsC;EACtD,kBAAkB,EAAE,6BAA6B;EACjD,SAAS,EAAE,kCAAkC;EAC7C,2BAA2B,EAAE,yCAAyC;EACtE,eAAe;AACjB","sourcesContent":[".google-btn {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start; /* Align content to the start */\n  padding: 0px 5px; /* Adjust padding if necessary */\n  border: none;\n  border-radius: 5px;\n  background-color: #4285f4; /* Google Blue */\n  color: #fff;\n  font-size: 16px;\n  font-weight: bold;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.google-btn svg {\n  margin-right: 10px; /* Add some space between the icon and text */\n}\n\n.google-btn:hover {\n  background-color: #3b78e7; /* Slightly darker shade of Google Blue */\n}\n\n.google-btn span {\n  margin: 0 auto; /* Center the text within the button */\n  position: absolute; /* Use absolute positioning */\n  left: 50%; /* Center based on the left edge */\n  transform: translateX(-50%); /* Adjust position to be truly centered */\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
