// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-field {
  flex-direction: row;
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}
.otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}

.resend {
  font-size: 12px;
}

.footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: black;
  font-size: 12px;
  text-align: right;
  font-family: monospace;
}

.footer a {
  color: black;
  text-decoration: none;
}

/* .otp-input {
  font-family: sans-serif;
  text-align: center;
  background-color: #f3f3f3;
  padding: 20px;
} */
`, "",{"version":3,"sources":["webpack://./src/shared/OtpVerification/OtpVerification.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,sCAAsC;AACxC;AACA;;EAEE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;;;;;GAKG","sourcesContent":[".otp-field {\n  flex-direction: row;\n  column-gap: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.otp-field input {\n  height: 45px;\n  width: 42px;\n  border-radius: 6px;\n  outline: none;\n  font-size: 1.125rem;\n  text-align: center;\n  border: 1px solid #ddd;\n}\n.otp-field input:focus {\n  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);\n}\n.otp-field input::-webkit-inner-spin-button,\n.otp-field input::-webkit-outer-spin-button {\n  display: none;\n}\n\n.resend {\n  font-size: 12px;\n}\n\n.footer {\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  color: black;\n  font-size: 12px;\n  text-align: right;\n  font-family: monospace;\n}\n\n.footer a {\n  color: black;\n  text-decoration: none;\n}\n\n/* .otp-input {\n  font-family: sans-serif;\n  text-align: center;\n  background-color: #f3f3f3;\n  padding: 20px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
