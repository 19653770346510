import React, { useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../shared/Toasters/toaster";
import { upload } from "../../../../user/userApi";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  addPortfolio,
  deleteCategory,
  getPortfolios,
  updateUser,
} from "../../../../../API/UserApi";
import Loader from "../../../../../utils/Loader";
import { getUserInfo } from "../../../../../common/getUserInfo";
import { Status } from "../../../../../constants/constant";
import { useNavigate } from "react-router-dom";
import { UseIsDesktopOrLaptop } from "../../../../../utils/Responsive/Responsive";
import DeleteButton from "../../../../../utils/Icons/DeleteButton";
import Swal from "sweetalert2";
import { getDeleteConfirmation } from "../../../../../utils";

const AddPortfolio = ({ formData, step, handleBackClick }) => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const [newCategory, setNewCategory] = useState(null);
  const [myPortfolios, setMyPortfolios] = useState([]);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isImgUploading, setIsImgUploading] = useState(false);
  const fileInputRef = useRef(null);
  const guid = uuidv4();
  const userInfo = getUserInfo();
  const navigate = useNavigate();

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setNewCategory({
      category: selectedValue,
      media: [],
    });
  };

  useEffect(() => {
    if (newCategory) {
      addMyPortfolio(newCategory);
    }
  }, [newCategory]);

  const getMyPortfolios = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getPortfolios();
      setMyPortfolios(res || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const addMyPortfolio = async (newCategory) => {
    setStatus(Status.PENDING);
    try {
      const res = await addPortfolio(newCategory);
      SuccessToaster(res);
      await getMyPortfolios({});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };
  const handleFileChange = async (e) => {
    setIsImgUploading(true);
    const selectedCategory = e.target.dataset.category;
    const files = e.target.files;
    // const uploadResponses = [];
    let mediaArrays = myPortfolios
      .filter((item) => item.category === selectedCategory)
      .flatMap((item) => item.media);
    if (
      files.length + mediaArrays.length >
      userInfo?.usePlanDetails?.pictureLimit
    ) {
      setIsImgUploading(false);
      ErrorToaster(
        `Maximum ${userInfo?.usePlanDetails?.pictureLimit} files are allowed.`
      );
      return;
    }
    const formDataArray = Array.from(files).map((file) => {
      const formData = new FormData();
      formData.append("file", file); // Append the file
      formData.append("guid", uuidv4()); // Append the guid
      formData.append("mediaType", "portfolio"); // Append the mediaType
      return formData;
    });
    try {
      const responses = await Promise.all(
        formDataArray.map((formData) => upload(formData))
      );
      if (responses.every((res) => res?.status === 200 && res?.data)) {
        setIsImgUploading(false);
        SuccessToaster("uploaded successfully.");
        const mediaArrays = myPortfolios
          .filter((item) => item.category === selectedCategory)
          .flatMap((item) => item.media);

        const uploadedMedia = responses.map((res) => res.data.high);
        const newMediaArray = [...mediaArrays, ...uploadedMedia];

        const newPortfolioData = {
          category: selectedCategory,
          media: newMediaArray,
        };

        await addMyPortfolio(newPortfolioData);
      } else {
        setIsImgUploading(false);
        ErrorToaster("Failed to upload one or more files. Please try again.");
      }
    } catch (err) {
      setIsImgUploading(false);
      ErrorToaster(
        err?.response?.data?.message ||
          "An error occurred while uploading files."
      );
    }
  };

  // const handleSave = () => {
  //   addMyPortfolio(newCategory);
  //   handleClose();
  // };

  const updateUserDetails = async () => {
    setStatus(Status.PENDING);
    try {
      delete formData.businessInsuranceFile;
      const data = await updateUser(formData);
      SuccessToaster(data);
      setStatus(Status.IDLE);
      navigate("/profile");
      window.location.reload();
      // await myDetails();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const handleSubmit = async () => {
    if (
      !myPortfolios?.every((portfolio) => portfolio.media.length >= 5) ||
      myPortfolios.length === 0
    ) {
      ErrorToaster("It is mandatory to upload a minimum of 5 images.");
    }
    updateUserDetails();
  };

  useEffect(() => {
    getMyPortfolios();
  }, []);

  const handleDeleteClick = async (portfolioIndex, imageIndex) => {
    const updatedPortfolios = [...myPortfolios][portfolioIndex];
    updatedPortfolios.media.splice(imageIndex, 1);
    const newPortfolioData = {
      category: updatedPortfolios.category,
      media: updatedPortfolios.media,
    };
    if (newPortfolioData) {
      await addMyPortfolio(newPortfolioData);
    }
  };

  const deletePortfolio = async (category) => {
    console.log("category", category);

    const isConfirmed = await getDeleteConfirmation({
      description: `Are you sure want to delete the ${category} category portfolio`,
    });
    if (!isConfirmed) return;
    const swalLoading = Swal.fire({
      title: "Please Wait",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });
    try {
      const { message } = await deleteCategory({ category });
      console.log("res", message);

      SuccessToaster(message);
      getMyPortfolios({});
    } catch (error) {
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    } finally {
      swalLoading.close();
    }
  };

  return (
    <div>
      <h4>Add Portfolio</h4>
      <hr className="opacity-100 mb60 mt10" />
      <div className="d-flex">
        <div className="col-sm-3">
          <div className="mb20">
            <div className="form-style1" style={{ marginTop: "-35px" }}>
              <label className="heading-color ff-heading fw500 mb10">
                Category (Choose One Category)
              </label>
              <div className="bootselect-multiselect">
                <select
                  className="form-control"
                  onChange={handleChange}
                  disabled={myPortfolios.length === 1}
                >
                  <option value="" selected disabled>
                    {" "}
                    Select
                  </option>
                  {formData?.skills?.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-sm-3">
          <div className="text-lg-end">
            <button
              className="ud-btn btn-dark"
              onClick={handleSave}
              disabled={myPortfolios.length === 1}>
              Add Category<i className="fal fa-arrow-right-long"></i>
            </button>
          </div>
        </div> */}
      </div>
      Note: You may select one category to upload your portfolio. Once your
      profile is verified, you will have the ability to manage your portfolio
      across various categories.
      {myPortfolios?.length > 0 && (
        <div className="row mt5" style={{ margin: "-15px" }}>
          {myPortfolios
            ?.slice(0, 3)
            .reverse()
            .map((portfolio, portfolioIndex) => (
              <div
                className="ps-widget bgc-white bdrs0 p10 mb30 overflow-hidden position-relative"
                style={{ borderRadius: "5px" }}
                key={portfolio?._id}
              >
                <div className="bdrb1 pb15 mb30 d-flex justify-content-between align-items-center">
                  <h5 className="list-title">{portfolio?.category}</h5>
                  <button
                    className="icon"
                    onClick={() => deletePortfolio(portfolio?.category)}
                  >
                    <DeleteButton />
                  </button>
                </div>
                <div className="col-xl-12">
                  <div className="d-flex flex-wrap flex-row align-content-stretch justify-content-start align-items-start mb40">
                    {portfolio?.media.map((imageUrl, imageIndex) => (
                      <div
                        key={imageIndex}
                        className="gallery-item me-3 mb-3 bdrs4 overflow-hidden position-relative"
                        style={{ width: "18%" }}
                      >
                        <div className="image-container">
                          <img
                            className="gallery-image"
                            src={imageUrl}
                            alt={`GalleryImage ${imageIndex + 1}`}
                          />
                        </div>
                        <div className="del-edit">
                          <div className="d-flex justify-content-center">
                            <button
                              className="icon"
                              onClick={() =>
                                handleDeleteClick(portfolioIndex, imageIndex)
                              }
                            >
                              <span className="flaticon-delete"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="gallery-item me-3 mb-3 bdrs4 overflow-hidden position-relative">
                      <label
                        htmlFor={`fileInput-${portfolioIndex}`}
                        className="w-60"
                        style={{ width: "205px" }}
                      >
                        <input
                          type="file"
                          className="d-none"
                          id={`fileInput-${portfolioIndex}`}
                          onChange={handleFileChange}
                          multiple
                          accept=".png, .jpg, .jpeg, .JPG"
                          data-category={portfolio?.category}
                        />
                        {isImgUploading ? (
                          <div style={{ margin: "60px" }}>
                            <Loader isUploading={true} />
                          </div>
                        ) : (
                          <img
                            className="w-60 rounded-2"
                            src="https://creativelayers.net/themes/freeio-html/images/gallery/g-1.png"
                            alt="UploadImage"
                            style={{
                              width: isDesktopOrLaptop ? "100%" : "40% ",
                            }}
                          />
                        )}
                      </label>
                    </div>
                    Please upload up to 10 high-quality pictures in the chosen
                    category. The verification process relies significantly on
                    the strength of your portfolio. It is mandatory to upload a
                    minimum of 5 images.
                  </div>
                </div>
                <hr />
              </div>
            ))}
        </div>
      )}
      <div className="d-flex justify-content-between col-md-6 mt45">
        <div className="col-md-6">
          <div className="text-start">
            <Button
              className="ud-btn btn-thm"
              type="submit"
              onClick={handleBackClick}
              // disabled={userInfo?.profileStatus === 'pending for review'}
            >
              Previous
              {/* <i className="fal fa-arrow-left-long"></i> */}
            </Button>
          </div>
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {(!myPortfolios?.every(
                (portfolio) => portfolio.media.length >= 5
              ) ||
                myPortfolios.length === 0) &&
                "To submit your profile for review, please ensure you have uploaded at least five photos in any category related to your chosen skills"}
            </Tooltip>
          }
        >
          <div className="text-start">
            <Button
              className="ud-btn btn-thm"
              type="button"
              onClick={handleSubmit}
              disabled={
                !myPortfolios?.every(
                  (portfolio) => portfolio.media.length >= 5
                ) || myPortfolios.length === 0
              }
            >
              {step === 1 ? "Next" : "Submit For Review"}
              {/* <i className="fal fa-arrow-right-long"></i> */}
            </Button>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default AddPortfolio;
