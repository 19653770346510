import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/dist/index.js';
import { selectAccessToken } from '../../auth/authSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Filters from '../../../shared/Filters/Filters';
import { useState } from 'react';
import { jobList } from '../../../API/JobsApi';
import Loader from '../../../utils/Loader';
import { EmptyComponent } from '../../../shared/Empty/Empty';
import { formatDate } from '../../../utils/Dates';
import { Status } from '../../../constants/constant';
import HiddenFilterBar from '../../../shared/HiddenFilterBar/HiddenFilterBar';
import MapComponent from '../../../shared/MapComponent/MapComponent';
import { useForm } from 'react-hook-form';
import { UseIsDesktopOrLaptop } from '../../../utils/Responsive/Responsive';
import AllFilterActionButton from '../../../shared/AllFilterActionButton/AllFilterActionButton';
import { AdditionalInfoRenderer } from '../../../utils/AdditionalInfoRenderer';

const JobListing = () => {
  const location = useLocation();
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const [showSideBar, setShowSideBar] = useState(false);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [jobs, setJobs] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterRateType, setFilterRateType] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [filterPrice, setFilterPrice] = useState(null);
  const [filterLocation, setFilterLocation] = useState('');
  const sidebarRef = useRef(null);
  const token = useSelector(selectAccessToken);
  const { register, setValue } = useForm();

  const searchedLocation = location?.state?.location;

  const getJobList = async () => {
    setStatus(Status.PENDING);
    try {
      const filterData = {
        level: filterLevel.toLowerCase() || '',
        jobType: filterRateType || '',
        jobRateMax: filterPrice.max,
        jobRateMin: filterPrice.min,
        location: filterLocation || searchedLocation,
      };
      if (filterSkills && filterSkills.length > 0) {
        filterData.categories = filterSkills;
      }

      const filteredFilterData = Object.fromEntries(
        Object.entries(filterData).filter(([_, value]) => value !== '')
      );

      const data = await jobList(filteredFilterData);
      setJobs(data || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      setError(error?.message);
    }
  };

  useEffect(() => {
    getJobList();
  }, [
    filterLevel,
    filterPrice,
    filterRateType,
    filterSkills,
    filterLocation,
    searchedLocation,
  ]);

  return (
    <>
      {showSideBar && (
        <HiddenFilterBar
          setShowSideBar={setShowSideBar}
          showSideBar={showSideBar}
          setFilterSkills={setFilterSkills}
          setFilterPrice={setFilterPrice}
          setFilterLevel={setFilterLevel}
          setFilterRateType={setFilterRateType}
          setFilterLocation={setFilterLocation}
          type="jobs"
        />
      )}
      <div className="body_content" ref={sidebarRef}>
        <section className="breadcumb-section" style={{ marginTop: '20px' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <div className="breadcumb-list">
                    {!token ? (
                      <Link to="/">Home</Link>
                    ) : (
                      <Link to="/profile">Profile</Link>
                    )}
                    <Link to="/jobs">Jobs</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Listings All Lists --> */}
        <section className="pt30 pb90">
          <div className="container">
            <div className="row col-lg-12">
              <div className=" col-lg-3">
                <Filters
                  setFilterSkills={setFilterSkills}
                  setFilterPrice={setFilterPrice}
                  setFilterLevel={setFilterLevel}
                  setFilterRateType={setFilterRateType}
                  setFilterLocation={setFilterLocation}
                  type="jobs"
                />{' '}
              </div>
              <div className="col-lg-9">
                <div className="row align-items-center mb20">
                  <div className="col-md-6">
                    <div className="text-center text-md-start">
                      <p className="text mb-0 mb10-sm">
                        <span className="fw500">({jobs?.length})</span> Jobs
                        Available
                      </p>
                    </div>
                  </div>
                  {!isDesktopOrLaptop && (
                    <div className="col-md-6 col-lg-12">
                      <div className=" p-0 mb-0 text-center">
                        <div className="search_area mb15 mr10">
                          <MapComponent
                            name="filterLocation"
                            register={register}
                            setValue={setValue}
                            setFilterLocation={setFilterLocation}
                            placeholder="Search by location..."
                          />
                          <label>
                            <span className="flaticon-loupe"></span>
                          </label>
                        </div>
                        <AllFilterActionButton
                          handleClick={() => setShowSideBar((prev) => !prev)}
                        />
                      </div>
                    </div>
                  )}
                  {/* <div className="col-md-6">
                    <div className="page_control_shorting d-md-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dropdown-lists d-block d-lg-none me-2 mb10-sm">
                        <ul className="p-0 mb-0 text-center text-md-start">
                          <li>
                            <button
                              type="button"
                              className="open-btn filter-btn-left"
                              onClick={() => setShowSideBar((prev) => !prev)}>
                              {' '}
                              <img
                                className="me-2"
                                src="https://creativelayers.net/themes/freeio-html/images/icon/all-filter-icon.svg"
                                alt=""
                              />{' '}
                              All Filter
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
                {status === Status.PENDING ? (
                  <Loader />
                ) : (
                  <div className="row">
                    {jobs?.map((job) => (
                      <div className="col-md-6 col-lg-12" key={job._id}>
                        <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-lg-center">
                          <div className="col-lg-8 ps-0">
                            <div className="d-lg-flex bdrr1 bdrn-xl pr15 pr0-lg">
                              <div className="thumb w60 position-relative rounded-circle mb15-md">
                                <img
                                  className="rounded-circle mx-auto"
                                  src={job.profileImg}
                                  alt=""
                                />
                                <span className="online-badge2"></span>
                              </div>
                              <div className="details ml15 ml0-md mb15-md">
                                <h5 className="title mb-3">{job?.title}</h5>
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="flaticon-place fz16 vam text-thm2 me-1"></i>{' '}
                                  {job?.location}
                                </p>
                                <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{' '}
                                  {formatDate(
                                    job?.creationDate ||
                                      '2023-11-07T17:25:35.030Z'
                                  )}
                                </p>
                                {/* <p className="mb-0 fz14 list-inline-item mb5-sm">
                                  <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{' '}
                                  {job?.proposal || 0}
                                </p> */}
                                <p className="mb-0 fz14 list-inline-item mb5-sm">
                                  <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{' '}
                                  {job?.category}
                                </p>
                                <p className="text mt10">
                                  <AdditionalInfoRenderer
                                    text={job?.description}
                                    maxLength={150}
                                  />
                                </p>
                                {/* <div className="skill-tags d-flex align-items-center justify-content-start">
                                  <span className="tag mx5" key={i}>
                                    {job?.category}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 ps-0 ps-xl-3 pe-0">
                            <div className="details">
                              <div className="text-lg-end">
                                {/* <h4>{`$${job.minRate} - $${job.maxRate}`}</h4> */}
                                <h4>${job?.jobRate}</h4>
                                <p className="text text-capitalize">
                                  {job?.budgetType}
                                </p>
                              </div>
                              <div className="d-grid mt15">
                                <Link
                                  to={`/jobs/${job._id}`}
                                  href="page-freelancer-single.html"
                                  className="ud-btn btn-light-thm">
                                  View Details
                                  <i className="fal fa-arrow-right-long"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {jobs.length === 0 && status === 'idle' && (
                  <EmptyComponent description="No Data Found." />
                )}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Our Footer -->  */}
        <a className="scrollToHome">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default JobListing;
