import React, { useState } from 'react';
import { levels } from '../../../dummyData';

const Levels = ({ setFilterLevel }) => {
  const [show, setShow] = useState(true);
  const [checkedLevel, setCheckedLevel] = useState(null);

  const handleCheckboxChange = (level) => {
    if (checkedLevel === level) {
      setCheckedLevel(null);
      setFilterLevel(null); // Clear filter if the same level is clicked again
    } else {
      setCheckedLevel(level);
      setFilterLevel(level);
    }
  };

  return (
    <div className="card mb20 pb5">
      <div className={`${show ? 'active' : ''}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}>
            Level
          </button>
        </h4>
      </div>
      <div
        id="collapse4"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="heading4"
        data-parent="#accordionExample">
        <div className="card-body card-body px-0 pt-0">
          {levels.map((level, index) => (
            <div key={index} className="checkbox-style1">
              <label className="custom_checkbox">
                {level}
                <input
                  type="checkbox"
                  checked={checkedLevel === level}
                  onChange={() => handleCheckboxChange(level)}
                />
                <span className="checkmark"></span>
                {/*<span className="right-tags">(1,945)</span>*/}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Levels;
