import Modal from 'react-bootstrap/Modal';

export function CategoryWarningModal({
  title,
  message,
  primaryButtonText,
  secondaryButtonText,
  handlePrimaryButtonClick,
  ...props
}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title || 'Information'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text" style={{ fontWeight: '500', fontSize: '18px' }}>
          {message}
        </p>
        {!props.isAuth && (
          <div className="d-flex justify-content-end mt20">
            <button
              className="ud-btn btn-thm-border mb25 me-4"
              onClick={props.onHide}>
              {secondaryButtonText}
            </button>
            {handlePrimaryButtonClick && (
              <button
                className="ud-btn btn-dark mb25 me-4"
                onClick={handlePrimaryButtonClick}>
                {primaryButtonText}
                <i className="fal fa-arrow-right-long"></i>
              </button>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
