import React from 'react';
import Category from './Category/Category';
import JobType from './JobType/JobType';
import Price from './Price/Price';
import Levels from './Levels/Levels';
import Location from './Location/Location';
import { UseIsDesktopOrLaptop } from '../../utils/Responsive/Responsive';

const Filters = ({
  setFilterSkills,
  filterSkills,
  setFilterPrice,
  setFilterLevel,
  setFilterRateType,
  setFilterLocation,
  showSideBar,
  type,
}) => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div
      className={`col-lg-12 list-sidebar-style1 ${
        !showSideBar && !isDesktopOrLaptop ? 'd-none' : 'd-lg-block'
      }`}>
      <div className="accordion" id="accordionExample">
        <Category
          setFilterSkills={setFilterSkills}
          filterSkills={filterSkills}
        />
        {type !== 'photographers' && (
          <JobType setFilterRateType={setFilterRateType} />
        )}
        <Price setFilterPrice={setFilterPrice} />
        {!showSideBar && (
          <Location setFilterLocation={setFilterLocation} filterType={type} />
        )}
        {type === 'photographers' && <Levels setFilterLevel={setFilterLevel} />}
      </div>
    </div>
  );
};

export default Filters;
