import React, { useState } from 'react';
import { formatDate } from '../../utils/Dates';
import { Rating } from 'react-simple-star-rating';
import './reviewCard.css';
import { ReportModal } from '../Modal/ReportModal';
import { Tooltip } from 'react-tooltip';
import { FeedBackModal } from '../Modal/FeedBack';

// const ReviewCard = ({ review, reviewersDetails }) => {
//   // const
//   return (
//     <div className="review-card mb30">
//       <div className="review-header d-flex align-items-center justify-content-between mb20">
//         <div className="reviewer-info d-flex align-items-center">
//           <img
//             src={
//               reviewersDetails[review?.userId]?.picture ||
//               reviewersDetails?.picture ||
//               'https://creativelayers.net/themes/freeio-html/images/blog/comments-2.png'
//             }
//             className="mr-3"
//             alt="reviewer-avatar"
//           />
//           <div>
//             <h6 className="mt-0 mb-0">
//               {reviewersDetails[review?.userId]?.name || reviewersDetails?.name}
//             </h6>
//             <span className="fz14">{formatDate(review?.date)}</span>
//           </div>
//         </div>
//         <Rating
//           initialValue={review?.rating}
//           size={20}
//           transition
//           emptyColor="gray"
//           strokeColor="gray"
//           strokeLinecap="round"
//           readonly
//         />
//       </div>
//       <p className="text mb20">{review?.message}</p>
//       <div className="review-footer d-flex justify-content-between">
//         {}
//         <div>
//           <button type="button" className="ud-btn btn-thm feedback">
//             Report
//           </button>
//         </div>
//         {/* Uncomment if you want to include thumbs-up and thumbs-down buttons */}
//         {/* <div className="review-cansel-btns d-flex">
//           <button type="button">
//             <i className="fas fa-thumbs-up"></i>Helpful
//           </button>
//           <button type="button">
//             <i className="fas fa-thumbs-down"></i>Not helpful
//           </button>
//         </div> */}
//       </div>
//     </div>
//   );
// };

const ReviewComponent = ({
  reviews,
  reviewersDetails,
  reviewJobs,
  jobDetail,
  type,
  reviewer,
}) => {
  const [show, setShow] = useState({ status: false, data: {} });
  const [feedBackModal, setFeedBackModal] = useState(false);

  const handleReportClick = (reviewId, jobId) => {
    setShow({ status: true, data: { reviewId, jobId } });
  };

  function calculateAverageRating(data) {
    // Check if the data array is empty
    if (data.length === 0) {
      return 0;
    }

    const totalRatings = data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.rating;
    }, 0);

    const averageRating = totalRatings / data.length;
    return Number.isInteger(averageRating)
      ? averageRating
      : averageRating.toFixed(2);
  }

  function calculateRatingDistribution(data) {
    const ratingCount = {
      '5 Star': 0,
      '4 Star': 0,
      '3 Star': 0,
      '2 Star': 0,
      '1 Star': 0,
    };

    data.forEach((item) => {
      if (item.rating === 5) {
        ratingCount['5 Star']++;
      } else if (item.rating === 4) {
        ratingCount['4 Star']++;
      } else if (item.rating === 3) {
        ratingCount['3 Star']++;
      } else if (item.rating === 2) {
        ratingCount['2 Star']++;
      } else if (item.rating === 1) {
        ratingCount['1 Star']++;
      }
    });

    return ratingCount;
  }

  return (
    <>
      <FeedBackModal
        show={feedBackModal}
        onClose={() => setFeedBackModal(false)}
        jobId={jobDetail?._id}
        jobPosterId={reviews[0].userId}
        reviewer="photographer"
      />
      {show.status && <ReportModal show={show} setShow={setShow} />}
      <div className="product_single_content mt30 mb60">
        <div className="mbp_pagination_comments">
          <div className="row">
            <div className="col-lg-12">
              <div className="total_review mb30">
                <h4>{reviews?.length} Reviews</h4>
              </div>
              <div className="d-md-flex align-items-center mb30">
                <div className="total-review-box d-flex align-items-center text-center mb30-sm">
                  <div className="wrapper mx-auto">
                    <div className="t-review mb15">
                      {calculateAverageRating(reviews || [])}
                    </div>
                    <h5>Exceptional</h5>
                    <p className="text mb-0">{reviews?.length} reviews</p>
                  </div>
                </div>
                {/* <RatingDistribution data={reviews} /> */}
              </div>
            </div>
            {reviews?.map((review) => (
              <div className="review-card mb30" key={review?._id}>
                <div className="review-header d-flex align-items-center justify-content-between mb20">
                  <div className="reviewer-info d-flex align-items-center">
                    <img
                      src={
                        reviewer === 'photographer'
                          ? reviewersDetails?.picture ||
                            'https://png.pngtree.com/png-vector/20191101/ourmid…on-color-simple-male-avatar-png-image_1934459.jpg'
                          : reviewersDetails[review?.userId]?.picture ||
                            reviewersDetails?.picture ||
                            'https://creativelayers.net/themes/freeio-html/images/blog/comments-2.png'
                      }
                      className="mr-3"
                      alt="reviewer-avatar"
                    />
                    <div>
                      <h6 className="mt-0 mb-0">
                        {reviewJobs?.[review?.jobId]?.title}
                      </h6>
                      <div>
                        <Rating
                          initialValue={review?.rating}
                          size={20}
                          transition
                          emptyColor="gray"
                          strokeColor="gray"
                          strokeLinecap="round"
                          readonly
                        />
                      </div>
                      <span className="fz14">{formatDate(review?.date)}</span>
                    </div>
                  </div>
                  {type === 'job' && reviewer !== 'photographer' && (
                    <div className="mb20">
                      {review?.isApproved && review?.isReported ? (
                        <span className="pending-style style6">Approved</span>
                      ) : !review?.isApproved && review?.isReported ? (
                        <>
                          <span
                            className="pending-style style6"
                            id="not-clickable">
                            Reported
                          </span>
                          <Tooltip anchorSelect="#not-clickable" place="top">
                            {review?.reportDescription}
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {!jobDetail.reviewGivenToJobPoster && (
                            <button
                              type="submit"
                              className="ud-btn btn-thm feedback mr10"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Review Job Poster"
                              onClick={() => setFeedBackModal(true)}>
                              Review Job Poster
                            </button>
                          )}
                          <button
                            type="submit"
                            className="ud-btn btn-thm feedback"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="You have already reported"
                            onClick={() =>
                              handleReportClick(review?._id, review?.jobId)
                            }>
                            Report
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <p className="text mb20">{review?.message}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewComponent;
