import { Modal } from 'react-bootstrap';
import { useState } from 'react';

export function Confirmation(props) {
  const { show, onClose, onConfirmation } = props;

  return (
    <Modal centered size="md" backdrop="static" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text" style={{ fontWeight: '500', fontSize: '18px' }}>
          Are you sure?
        </p>
        <div className="d-flex justify-content-end">
          <button
            className="login-info home18-sign-btn px15 ml10 bdr1-dark mr10"
            style={{ width: '80px', borderRadius: '5px' }}
            onClick={onClose}>
            Close
          </button>
          <button
            className="mr5 ud-btn add-joining home20-join-btn text-white"
            onClick={onConfirmation}>
            <span style={{ cursor: 'pointer', marginTop: '2px' }}>Ok</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
