import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { photographerDetails } from '../../../../API/PhotographerApi';
import { ErrorToaster } from '../../../../shared/Toasters/toaster';
import Loader from '../../../../utils/Loader';
import { formatDate } from '../../../../utils/Dates';
import { EmptyComponent } from '../../../../shared/Empty/Empty';
import { getUserInfo } from '../../../../common/getUserInfo';
import { userDetails } from '../../../../API/AdminApi';
import { UserTypes } from '../../../../constants/constant';

const JobPosterDetails = () => {
  const { id } = useParams();
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [photographerInfo, setPhotographerInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('portfolio');
  const userInfo = getUserInfo();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const getPhotographerDetails = async () => {
    setStatus('loading');
    try {
      const data =
        userInfo?.userType === 'admin'
          ? await userDetails({ userId: id, userType: UserTypes.PHOTOGRAPHER })
          : await photographerDetails(id);
      setPhotographerInfo(data || null);
      setStatus('idle');
    } catch (error) {
      setStatus('rejected');
      ErrorToaster('Something went wrong.');
      setError(error?.message);
    }
  };
  useEffect(() => {
    getPhotographerDetails();
  }, [id]);

  const skills = ['HTML', 'CSS', 'Advertising-product'];
  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  const getAllMediaForPhotographer = (portfolioData) => {
    if (selectedCategory === 'All') {
      // If 'All' is selected, display all media
      return portfolioData?.flatMap((portfolio) => portfolio.media);
    } else {
      // If any other category is selected, filter and display media for that category
      const filteredMedia = portfolioData
        ?.filter((portfolio) => portfolio.category === selectedCategory)
        .flatMap((portfolio) => portfolio.media);
      return filteredMedia;
    }
    // return portfolioData?.flatMap((portfolio) => portfolio.media)
  };

  return (
    <div className="body_content">
      {/* <!-- Breadcumb Sections --> */}
      {!userInfo.userType === 'admin' && (
        <section className="breadcumb-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-lg-10">
                <div className="breadcumb-style1 mb10-xs">
                  <div className="breadcumb-list">
                    <Link to="/">Home</Link>
                    <Link to="/photographers">Photographers</Link>
                    <Link to={`/photographers/${id}`}>Photographer Detail</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <!-- Service Details --> */}
      {status === 'loading' ? (
        <Loader />
      ) : (
        <section className="pt10 pb90 pb30-md">
          <div className="container">
            <div className="row wrap wow fadeInUp">
              <div className="col-lg-8">
                <div className="column">
                  <div className="cta-service-v1 freelancer-single-v1 pt60 pb60 bdrs16 position-relative overflow-hidden d-flex align-items-center">
                    <img
                      className="left-top-img wow zoomIn"
                      src="images/vector-img/left-top.png"
                      alt=""
                    />
                    <img
                      className="right-bottom-img wow zoomIn"
                      src="images/vector-img/right-bottom.png"
                      alt=""
                    />
                    <div className="row wow fadeInUp">
                      <div className="col-xl-12">
                        <div className="position-relative pl50 pl20-sm">
                          <div className="list-meta d-sm-flex align-items-center">
                            <div className="thumb w90 position-relative rounded-circle mb-3 mb-lg-0">
                              <img
                                className="rounded-circle mx-auto"
                                style={{
                                  width: '85px',
                                  height: '85px', // Set a fixed height
                                  objectFit: 'cover', // Maintain aspect ratio and cover the container
                                  borderRadius: '50%', // Make the image round by setting border radius to 50%
                                }}
                                src={
                                  photographerInfo?.profilePicture ||
                                  'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg'
                                }
                                alt="Profile Picture"
                              />
                              <span className="online"></span>
                            </div>

                            <div className="ml20 ml0-xs">
                              <h5 className="title mb-1">
                                {photographerInfo?.fullName}
                              </h5>
                              <p className="mb-0">{photographerInfo?.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt30">
                    <div className="col-sm-6 col-xl-3">
                      <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div className="icon flex-shrink-0">
                          <span className="flaticon-goal"></span>
                        </div>
                        <div className="details">
                          <h5 className="title">Total Jobs</h5>
                          <p className="mb-0 text">
                            {photographerInfo?.totalJobCount || 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-about">
                    <h4>Description</h4>
                    <p className="text mb60">
                      {photographerInfo?.bio || (
                        <EmptyComponent description="No Data." type={true} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="column">
                  <div className="blog-sidebar ms-lg-auto">
                    <div className="price-widget pt25 bdrs12 default-box-shadow1s">
                      <div className="category-list mt20">
                        <a
                          className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                          href="">
                          <span className="text">
                            <i className="flaticon-place text-thm2 pe-2 vam"></i>
                            Location
                          </span>{' '}
                          <span className="Name">
                            {photographerInfo?.photographerDetails?.location ||
                              'London, UK'}
                          </span>
                        </a>
                        <a
                          className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                          href="">
                          <span className="text">
                            <i className="flaticon-30-days text-thm2 pe-2 vam"></i>
                            Member since
                          </span>{' '}
                          <span className="Name">
                            {formatDate(
                              photographerInfo?.creationDate ||
                                '2023-11-07T17:25:35.030Z'
                            )}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default JobPosterDetails;
