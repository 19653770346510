import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  GoogleLoginAsync,
  clearError,
  clearMessage,
  loginUserAsync,
  mobileLoginAsync,
  responseMessage,
  responseStatus,
  selectAccessToken,
  selectError,
} from '../../authSlice';
import { useEffect } from 'react';
import {
  ErrorToaster,
  SuccessToaster,
} from '../../../../shared/Toasters/toaster';
import { myDetail } from '../../../../API/UserApi';
import { Status } from '../../../../constants/constant';
import ProfileRegistration from '../../../user/components/ProfileRegistration/ProfileRegistration';
import OtpVerificationModal from '../../../../shared/OtpVerification/OtpVerification';
import GoogleButton from '../../../../utils/Icons/GoogleButton/GoogleButton';
import { useGoogleLoginHook } from '../../../../utils/hooks/useGoogleLoginHook';

export const Login = ({ userType }) => {
  const dispatch = useDispatch();
  const { login: googleLogin, profile } = useGoogleLoginHook();
  const navigate = useNavigate();
  const status = useSelector(responseStatus);
  const message = useSelector(responseMessage);
  const token = useSelector(selectAccessToken);
  const error = useSelector(selectError);
  const [isLoading, setIsLoading] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMobileSignup, setShowMobileSignup] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const myDetails = async () => {
    setIsLoading(true);
    try {
      const data = await myDetail();
      if (data) {
        data.profileStatus === Status.INCOMPLETE && setShowModal(true);
      }
      setUserInfo(data);
      localStorage.setItem('currentUser', JSON.stringify(data));
      setIsLoading(false);
    } catch (error) {
      console.error('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (status === 'success' && message === 'OTP Sent') {
      setShow(true);
      SuccessToaster(message);
    }
    if (status === 'success' && message !== 'OTP Sent') {
      myDetails();
    }
    if (error) {
      ErrorToaster(error);
    }
    return () => {
      dispatch(clearError());
      dispatch(clearMessage());
    };
  }, [status, error, dispatch]);

  useEffect(() => {
    localStorage.setItem('accessToken', token);
  }, [token]);

  // const onSuccess = async (response) => {
  //   const userObj = jwtDecode(response.credential);
  //   const formValue = {
  //     email: userObj.email,
  //     fullName: userObj.name,
  //     userType: userType === 'job-poster' ? 'user' : userType,
  //   };
  //   dispatch(GoogleLoginAsync(formValue));
  // };

  useEffect(() => {
    if (!!profile) {
      const formValue = {
        email: profile?.email,
        fullName: profile?.name,
        userType: userType === 'job-poster' ? 'user' : userType,
      };
      dispatch(GoogleLoginAsync(formValue));
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const login = async (data) => {
    let formData = {};
    if (showMobileSignup) {
      formData = {
        mobile: data?.mobile,
        userType: userType === 'job-poster' ? 'user' : userType,
      };
      setUserData(formData);
      dispatch(mobileLoginAsync(formData));
    } else {
      formData = {
        email: data?.email,
        password: data?.password,
        userType: userType === 'job-poster' ? 'user' : userType,
      };
      dispatch(loginUserAsync(formData));
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.profileStatus !== Status.INCOMPLETE) {
      switch (userInfo.userType) {
        case 'photographer':
          navigate('/jobs', { replace: true });
          break;
        case 'user':
          navigate('/photographers', { replace: true });
          break;
        case 'admin':
          navigate('/manage-photographers', { replace: true });
          break;
        case 'agency':
          navigate('/jobs', { replace: true });
          break;
        default:
          break;
      }
    }
  }, [userInfo, navigate]);

  function getSubHeading(userType) {
    switch (userType) {
      case 'photographer':
        return 'Sign in to explore job opportunities, manage your portfolio, and access your account.';
      case 'job-poster':
        return 'Create your job poster account to access a pool of talented photographers and manage your projects.';
      case 'agency':
        return 'Sign in to explore job opportunities, manage your portfolio, and access your account.';
      case 'admin':
        return 'Sign in manage the application.';
      default:
        return 'Unknown user type. Please provide a valid user type.';
    }
  }

  return (
    <>
      {show ? (
        <OtpVerificationModal
          userData={userData}
          show={show}
          handleClose={() => setShow(false)}
          setShow={setShow}
          type="mobileLogin"
        />
      ) : (
        <>
          {showModal && (
            <ProfileRegistration
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
          <div className="body_content" id="container">
            <section>
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 m-auto wow fadeInUp mt-2"
                    data-wow-delay="300ms">
                    <div className="main-title text-center mb500">
                      <h2
                        className="title text-capitalize"
                        style={{
                          color: 'var(--headings-color)',
                          fontFamily: 'var(--title-font-family)',
                          lineHeight: 1.3125,
                        }}>
                        Sign in as a{' '}
                        {userType === 'job-poster' ? 'Job Poster' : userType}
                      </h2>
                      <p className="paragraph">{getSubHeading(userType)}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="row wow fadeInRight mb200"
                  style={{ marginBottom: '200px' }}
                  data-wow-delay="300ms">
                  <div className="col-xl-6 mx-auto">
                    <div className="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                      <div className="mb30">
                        <h4
                          style={{
                            color: 'var(--headings-color)',
                            fontFamily: 'var(--title-font-family)',
                            lineHeight: 1.3125,
                          }}>
                          We're Glad To See You Again!
                        </h4>
                        <p className="text">
                          Don't Have An Account?{' '}
                          <Link to={`/signup/${userType}`} className="text-thm">
                            Sign Up!
                          </Link>
                        </p>
                      </div>
                      <form noValidate onSubmit={handleSubmit(login)}>
                        {!showMobileSignup ? (
                          <>
                            <div className="mb-3">
                              <label
                                htmlFor="email"
                                className="form-label fw500 dark-color">
                                Email
                              </label>
                              <input
                                id="email"
                                {...register('email', {
                                  required: 'Email is required',
                                  pattern: {
                                    value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi,
                                    message: 'Email not valid',
                                  },
                                })}
                                type="email"
                                className="form-control"
                                placeholder="Please Enter Your Email"
                              />
                              {errors.email && (
                                <p className="text-danger">
                                  {errors.email.message}
                                </p>
                              )}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="password"
                                className="form-label fw500 dark-color">
                                Password
                              </label>
                              <input
                                id="password"
                                {...register('password', {
                                  required: 'Password is required',
                                })}
                                type="password"
                                className="form-control"
                                placeholder="Please Enter Your Password"
                              />
                              {errors.password && (
                                <p className="text-danger fw500">
                                  {errors.password.message}
                                </p>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="mb-3">
                            <label
                              htmlFor="mobile"
                              className="form-label fw500 dark-color">
                              Phone
                            </label>
                            <input
                              id="mobile"
                              {...register('mobile', {
                                required: 'Mobile is required',
                                maxLength: {
                                  value: 10,
                                  message: 'Mobile number must be 10 digits',
                                },
                              })}
                              type="mobile"
                              className="form-control"
                              placeholder="Please Enter Your Mobile Number"
                              autoComplete="off"
                            />
                            {errors.phone && (
                              <p style={{ color: 'red' }}>
                                {errors.phone.message}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="form-check mb-3">
                          <Link
                            to="/forget-password"
                            state={{
                              userType:
                                userType === 'job-poster' ? 'user' : userType,
                            }}
                            className="text-thm float-end">
                            Forgot Your Password?
                          </Link>
                        </div>
                        <div className="d-grid mb20">
                          <button className="ud-btn btn-thm" type="submit">
                            Log In
                          </button>
                        </div>
                      </form>
                      <div className="d-grid mb20">
                        <button
                          className="ud-btn btn-thm"
                          onClick={() => setShowMobileSignup((prev) => !prev)}>
                          {showMobileSignup
                            ? 'Login With Email'
                            : 'Login With Mobile'}
                        </button>
                      </div>
                      <div className="d-grid mb20" onClick={googleLogin}>
                        <GoogleButton />
                      </div>
                      {/* </div> */}
                      {/* </div> */}

                      {/* <div className="hr_content mb20">
                    <hr />
                    <span className="hr_top_text">OR</span>
                  </div>
                  <div className="d-md-flex justify-content-center">
                    <button
                      className="ud-btn btn-google fz14 fw400 mb-2 mb-md-0"
                      type="submit">
                      <i className="fab fa-google"></i> Continue Google
                    </button>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
