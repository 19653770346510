import * as React from 'react';

function ArrowUp(props) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.19823 10.1323L8.71198 6.67401C8.78864 6.59816 8.89213 6.55562 8.99998 6.55562C9.10782 6.55562 9.21131 6.59816 9.28801 6.67401L12.8018 10.1323"
        stroke="#1b75bb"
        strokeWidth="0.75"
      />
    </svg>
  );
}

export default ArrowUp;
