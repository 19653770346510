import React, { Suspense } from 'react';
import Loader from '../../../../utils/Loader';

const QueriesTable = React.lazy(() => import('./QueriesListing'));

const ManageQueries = () => {
  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <div className="tab-content">
          <Suspense fallback={<Loader />}>
            <QueriesTable />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default ManageQueries;
