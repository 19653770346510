function StudioSpaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      width="22"
      height="24"
      viewBox="0 0 526.341 532.97375"
      style={{ enableBackground: 'new 0 0 526.341 426.379' }}
      xml="preserve">
      <g>
        <path d="M138.284,26.729h249.773c0.247,6.795,0.702,19.096,1.306,34.458h5.774c-0.936-23.792-1.516-40.223-1.516-40.223H132.724   c0,0-0.58,16.43-1.516,40.223h5.771C137.582,45.825,138.037,33.525,138.284,26.729z" />
        <path d="M77.175,283.785h10.828v-11.338c-4.261,3.379-8.079,6.223-10.828,8.219V283.785z" />
        <path d="M405.148,235.371c-1.379-5.515-2.964-26.907-4.509-54.184h-5.759c1.584,27.824,3.225,49.78,4.675,55.58   c3.319,13.277,22.559,30.351,36.476,41.254H219.003v5.764h230.164v-3.122C437.949,272.519,408.859,250.214,405.148,235.371z" />
        <path d="M73.175,286.629V135.041h3.299c2.287,9.377,9.793,34.676,26.201,45.166c24.334-9.666,50-11,50-11s-9.5-46.5,0.666-96.334   c-66.27,4.064-76.549,37.076-77.571,41.168H65.009c-5.521,0-10,4.477-10,10v1c0,5.523,4.479,10,10,10h2.166v152.021L1.158,339.139   c-1.308,1.018-1.543,2.902-0.526,4.209c1.018,1.309,2.902,1.545,4.21,0.527l62.333-49.168v19.5c0,1.656,1.344,3,3,3   c1.658,0,3-1.344,3-3v-19.936l24.829,19.585v-7.642L73.175,286.629z" />
        <rect x="128.112" width="270.119" height="13.689" />
        <path d="M525.183,339.139l-66.017-52.076V135.041h2.166c5.521,0,10-4.477,10-10v-1c0-5.523-4.479-10-10-10h-10.761   c-1.022-4.092-11.302-37.104-77.571-41.168c10.166,49.834,0.666,96.334,0.666,96.334s25.666,1.334,50,11   c16.408-10.49,23.914-35.789,26.201-45.166h3.299v151.588l-66.568,52.51c-1.307,1.018-1.543,2.902-0.525,4.209   c1.018,1.309,2.902,1.545,4.209,0.527l62.885-49.604v19.936c0,1.656,1.342,3,3,3c1.656,0,3-1.344,3-3v-19.5l62.333,49.168   c1.308,1.018,3.192,0.781,4.21-0.527C526.726,342.041,526.491,340.156,525.183,339.139z" />
        <path d="M205.521,266.899c-0.266-1.425-1.5-2.45-2.949-2.45H191.57h-11.002c-1.448,0-2.683,1.025-2.949,2.45l-0.66,3.55   c0.104,1.107,0.236,2.179,0.419,3.186c0.526,3.043,1.422,5.418,2.597,6.895c0.155,0.101,0.543,0.313,1.115,0.531l1.975-10.612   h6.506v11.658c1.348-0.022,2.344-0.106,2.647-0.136c0.341-0.035,0.707-0.057,1.073-0.057c0.095,0,0.186,0.011,0.28,0.013v-11.478   h6.507l3.299,17.739l4.887,26.262h-33.385l2.535-13.629c-1.75-0.452-3.719-1.065-5.726-2.038l-2.427,13.047v4.185v28.629   l4.625-24.861h35.368l16.335,87.804c0.303,1.629,1.868,2.706,3.498,2.403c1.629-0.305,2.703-1.871,2.4-3.498v-0.002   l-22.006-118.302L205.521,266.899z" />
        <path d="M216.071,254.949v-26c0-2.762-2.238-5-5-5h-15.5v-4.125c0-1.657-1.342-3-3-3h-14.875c-1.656,0-3,1.343-3,3v4.125h-2.625   c-2.762,0-5,2.238-5,5v9.94c1.149,0.454,2.228,1.05,3.15,1.78c1.099,0.848,2.041,1.88,2.852,3.05v-10.77h28v20.666h-24.815   c0.078,0.848,0.133,1.681,0.184,2.503l0.015,0.237c0.052,1.195,0.079,2.398,0.103,3.594h34.513   C213.833,259.949,216.071,257.71,216.071,254.949z M204.739,244.865c0-2.164,1.754-3.917,3.916-3.917   c2.164,0,3.916,1.753,3.916,3.917c0,2.163-1.752,3.916-3.916,3.916C206.493,248.781,204.739,247.028,204.739,244.865z" />
        <rect x="198.571" y="219.324" width="8.75" height="2.625" />
        <path d="M158.217,237.895c4.891-4.127,8-10.298,8-17.199c0-12.426-10.074-22.5-22.5-22.5c-12.425,0-22.499,10.074-22.499,22.5   c0,6.901,3.109,13.072,7.999,17.199H158.217z" />
        <path d="M174.459,256.478c-0.184-2.973-0.328-5.8-1.654-9.139c-0.688-1.645-1.824-3.56-3.822-5.102   c-1.975-1.562-4.686-2.35-6.953-2.308c-0.389,0-0.768,0.037-1.141,0.089c-0.439-0.077-0.891-0.125-1.355-0.125h-38.634   c-0.605,0-1.192,0.077-1.758,0.209c-3.078,0.423-5.555,1.594-8.113,3.003c-4.551,2.641-9.029,6.504-12.738,11.372   c-3.641,4.856-6.66,10.831-6.713,17.914c-0.004,2.516,0.437,5.145,1.394,7.683c0.029,0.083,0.051,0.168,0.083,0.251v-0.002   c0.001,0.001,0.001,0.002,0.001,0.003c0,0,0.001,0.001,0.001,0.002c0.001,0.002,0.002,0.004,0.003,0.006   c2.004,5.188,6.098,9.659,11.682,13.022c1.943,1.176,4.089,2.242,6.431,3.218V278.39c-1.962-1.519-2.791-2.828-3.205-3.862   c-0.001-0.002-0.001-0.003-0.003-0.005c-0.265-0.692-0.384-1.349-0.388-2.13c-0.008-1.382,0.459-3.216,1.525-5.227   c0.963-1.858,2.43-3.795,4.07-5.513v152.362c0,6.83,5.537,12.363,12.363,12.363c6.828,0,12.364-5.533,12.364-12.363v-90.271h4.635   v90.271c0,6.83,5.537,12.363,12.363,12.363c6.828,0,12.365-5.533,12.365-12.363V293.255c3.381,3.37,7.393,4.811,10.932,5.701   c4.029,0.963,7.816,1.147,10.73,1.156c2.975-0.002,5.02-0.224,5.23-0.246c4.395-0.482,7.563-4.434,7.08-8.825   c-0.484-4.393-4.438-7.563-8.83-7.078c-0.18,0.018-1.586,0.149-3.48,0.149c-2.104,0.006-4.84-0.184-6.98-0.711   c-2.199-0.487-3.445-1.401-3.449-1.522c-1.41-1.719-2.48-4.402-3.084-7.889C174.465,268.766,174.707,262.32,174.459,256.478z" />
      </g>
    </svg>
  );
}

export default StudioSpaceIcon;
