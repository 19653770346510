import * as _ from 'lodash';

import { axiosWithToken, axiosWithoutToken } from '../common/axiosConfig';
import {
  PhotographersListURL,
  PhotographerDetailsURL,
  SendProposalURL,
  MyAppliedJobURL,
  MyRunningJobsListURL,
  MyActivePlanURL,
  ReportReviewURL,
  ReviewJobPosterURL,
} from '../constants/apiConstants';

export async function photographersList(filterData) {
  return axiosWithoutToken
    .post(PhotographersListURL, filterData || {})
    .then((res) => {
      return _.get(res, 'data.data', {});
    });
}

export async function photographerDetails(userId) {
  return axiosWithToken.post(PhotographerDetailsURL, { userId }).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function applyJob(formValue) {
  return axiosWithToken.post(SendProposalURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function myAppliedJobs() {
  return axiosWithToken.post(MyAppliedJobURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function myRunningJobs() {
  return axiosWithToken.post(MyRunningJobsListURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function myCompletedJobs() {
  return axiosWithToken.post(MyRunningJobsListURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function getMyActivePlan() {
  return axiosWithToken.get(MyActivePlanURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function reportReview(value) {
  return axiosWithToken.post(ReportReviewURL, value).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function reviewJobPoster(value) {
  return axiosWithToken.post(ReviewJobPosterURL, value).then((res) => {
    return _.get(res, 'data.message', {});
  });
}
