import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatDate, oneMonthLater } from "../../../../utils/Dates";
import { getMyActivePlan } from "../../../../API/PhotographerApi";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import Loader from "../../../../utils/Loader";
import { Status } from "../../../../constants/constant";
import { Button } from "react-bootstrap";
import { createBillingPortal } from "../../../../API/StripeApi";

const MyPlan = () => {
  const [myActivePlan, setMyActivePlan] = useState([]);
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const myActivePlanDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getMyActivePlan();
      setMyActivePlan(res || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const createCustomerPortal = async () => {
    setIsLoading(true);
    try {
      const res = await createBillingPortal();
      window.open(res, "_blank"); // Opens the link in a new tab
      setIsLoading(false);
    } catch (error) {
      setStatus(false);
      ErrorToaster("Something went wrong.");
    }
  };

  useEffect(() => {
    myActivePlanDetails();
  }, [location.key]);

  return (
    <>
      {status === Status.PENDING && myActivePlan ? (
        <div style={{ marginBottom: "50px" }}>
          <Loader />
        </div>
      ) : (
        <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
          {/* <div className="bdrb1 pb15 mb25">
            <h5 className="list-title">My Subscription</h5>
          </div> */}
          <div>
            <div className="d-flex justify-content-between">
              <div className="mb-4">
                <h4>Current Plan</h4>
                <div className=" align-items-center mt-3">
                  <span
                    className="mr-2 fz16"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    {myActivePlan.userPlanType === "Free"
                      ? "Free Plan"
                      : `${myActivePlan.userPlanType} Plan / ${myActivePlan.userPlanInterval}ly`}
                  </span>
                  <div className="d-flex justify-content-between">
                    {/* <div> */}
                    <Link
                      className="ud-btn btn-thm-border hover-default-box-shadow1 text-decoration-none"
                      style={{
                        borderRadius: "30px",
                        marginTop: "20px",
                      }}
                      to="/subscriptions"
                      state={{
                        data: {
                          activePlanType: myActivePlan?.usePlanDetails?.id,
                          activePlanInterval: myActivePlan?.userPlanInterval,
                        },
                      }}
                    >
                      Change Plan
                    </Link>
                    {/* </div> */}
                    {/* <div> */}
                    <Button
                      className="ud-btn btn-thm-border hover-default-box-shadow1 ml5"
                      style={{
                        borderRadius: "30px",
                        marginTop: "20px",
                      }}
                      onClick={createCustomerPortal}
                    >
                      {isLoading ? <span>Loading...</span> : "Manage billing"}
                    </Button>
                    {/* </div> */}
                  </div>
                </div>
                <div className="mt20">
                  <div className="mb-4">
                    <h4>Current Billing Cycle</h4>
                    <div className=" align-items-center mt-3">
                      <span
                        className="mr-2 fz16"
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        {formatDate(
                          myActivePlan?.planAllotmentDate ||
                            "2023-11-07T17:25:35.030Z"
                        )}{" "}
                        -{" "}
                        {formatDate(
                          oneMonthLater(
                            myActivePlan?.planAllotmentDate ||
                              "2023-11-07T17:25:35.030Z"
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4>Remaining Jobs</h4>
                    <div className=" align-items-center mt-3">
                      <span
                        className="mr-2 fz16"
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        {myActivePlan?.usePlanDetails?.jobLimit -
                          myActivePlan.appliedJobs}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div
                  className="pricing_packages at-home2 text-center bdrs16"
                  style={{
                    border: "1px solid grey",
                    backgroundColor: "#FFEDE8",
                  }}
                >
                  <div className="heading mb10">
                    <h1 className="text2">
                      ${myActivePlan?.usePlanDetails?.amount}{" "}
                      <small>/ {myActivePlan?.userPlanInterval}</small>
                    </h1>
                    <h4 className="package_title mt-2">
                      {myActivePlan?.userPlanType}
                    </h4>
                  </div>
                  <div className="details">
                    <div className="pricing-list mb40">
                      <ul className="px-0">
                        <li className="fz16">
                          Apply for up to{" "}
                          {myActivePlan?.usePlanDetails?.jobLimit} jobs per
                          month.
                        </li>
                        <li className="fz16">
                          Choose {myActivePlan?.usePlanDetails?.categoryLimit}{" "}
                          categories to display your work.
                        </li>
                        <li className="fz18">
                          Feature up to{" "}
                          {myActivePlan?.usePlanDetails?.pictureLimit} photos in
                          each selected category.
                        </li>
                        {/* <li>{plan?.isSearchable && 'Searchable'}</li> */}
                      </ul>
                    </div>
                    <div className="d-grid">
                      <Link
                        className="ud-btn btn-light-thm text-decoration-none"
                        to="/subscriptions"
                        state={{
                          data: {
                            activePlanType: myActivePlan?.usePlanDetails?.id,
                            activePlanInterval: myActivePlan?.userPlanInterval,
                          },
                        }}
                      >
                        Change Plan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <hr className="opacity-100 mb30 mt40" /> */}

            {/* Second Section */}
            {/* <div className="mb-4">
              <h4>Current Billing Cycle</h4>
              <div className=" align-items-center mt-3">
                <span
                  className="mr-2"
                  style={{
                    marginRight: '10px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                  }}>
                  {formatDate(
                    myActivePlan?.planAllotmentDate ||
                      '2023-11-07T17:25:35.030Z'
                  )}{' '}
                  -{' '}
                  {formatDate(
                    oneMonthLater(
                      myActivePlan?.planAllotmentDate ||
                        '2023-11-07T17:25:35.030Z'
                    )
                  )}
                </span>
              </div>
            </div>
            <hr className="opacity-100 mb30 mt40" />
            <div className="mb-4">
              <h4>Remaining Jobs</h4>
              <div className=" align-items-center mt-3">
                <span
                  className="mr-2"
                  style={{
                    marginRight: '10px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                  }}>
                  {myActivePlan?.usePlanDetails?.jobLimit -
                    myActivePlan.appliedJobs}
                </span>
              </div>
            </div>
            <hr className="opacity-100 mb30 mt40" /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default MyPlan;
