import React, { useEffect, useRef, useState } from 'react';
import Filters from '../Filters/Filters';

const HiddenFilterBar = ({
  setShowSideBar,
  showSideBar,
  setFilterSkills,
  setFilterPrice,
  setFilterLevel,
  setFilterRateType,
  setFilterLocation,
  type,
}) => {
  return (
    <div
      // className="lefttside-hidden-bar"
      style={{
        backgroundColor: '#ffffff',
        boxShadow: '0px 6px 15px rgba(64, 79, 104, 0.05)',
        WebkitBoxShadow: '0px 6px 15px rgba(64, 79, 104, 0.05)',
        MozBoxShadow: '0px 6px 15px rgba(64, 79, 104, 0.05)',
        OBoxShadow: '0px 6px 15px rgba(64, 79, 104, 0.05)',
        boxShadow: '0px 6px 15px rgba(64, 79, 104, 0.05)',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        right: 'auto',
        top: '0px',
        width: '400px',
        zIndex: '99999',
        // opacity: '0',
        // visibility: 'hidden',
        transition: 'all 0.4s ease',
        WebkitTransition: 'all 0.4s ease',
        MozTransition: 'all 0.4s ease',
        MsTransition: 'all 0.4s ease',
        OTransition: 'all 0.4s ease',
        transition: 'all 0.4s ease',
        maxWidth: '350px',
      }}>
      <div className="hsidebar-header bdrb1">
        <h4 className="list-title">All filters</h4>
        <div className="sidebar-close-icon">
          <span
            className="far fa-times"
            onClick={() => setShowSideBar(false)}></span>
        </div>
      </div>
      <div className="hsidebar-content">
        <div className="widget-wrapper">
          <Filters
            setFilterSkills={setFilterSkills}
            setFilterPrice={setFilterPrice}
            setFilterLevel={setFilterLevel}
            setFilterRateType={setFilterRateType}
            showSideBar={showSideBar}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export default HiddenFilterBar;
