import React from 'react';
import Faq from '../../components/Faq/Faq';

const FaqPage = () => {
  return (
    <div>
      <Faq />
    </div>
  );
};

export default FaqPage;
