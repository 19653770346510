import React, { useEffect } from 'react';
import SignUp from '../../features/auth/components/Signup/SignUp';
import { useNavigate, useParams } from 'react-router-dom';

const SignupPage = () => {
  let { userType } = useParams();
  const navigate = useNavigate();

  // Define an array of allowed user types

  // Check if the userType is not in the allowed list
  useEffect(() => {
    const allowedUserTypes = ['job-poster', 'photographer', 'agency'];
    if (!allowedUserTypes.includes(userType)) {
      // If userType is not allowed, redirect to 404 page
      navigate('/404', { replace: true });
    }
  }, [navigate, userType]);
  return (
    <div>
      <SignUp userType={userType} />
    </div>
  );
};

export default SignupPage;
