import { axiosWithToken } from '../../common/axiosConfig';
import {
  UserDetailsURL,
  UpdateUserURL,
  UploadURL,
  AddPortfolioURL,
  GetUserPortfoliosURL,
  UpdateUserEmailVerifyURL,
  UpdateUserEmailStartURL,
} from '../../constants/apiConstants';

export function fetchMyDetail() {
  return new Promise(async (resolve, reject) => {
    const response = await axiosWithToken.get(`${UserDetailsURL}`);
    if (response.status === 200) {
      const data = response.data;
      resolve({ data });
    } else {
      const error = response;
      reject(error);
    }
  });
}

export function updateUserDetails(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.put(UpdateUserURL, formValue);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function updateUserEmailStart(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.post(
        UpdateUserEmailStartURL,
        formValue
      );
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function updateUserEmailVerify(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.post(
        UpdateUserEmailVerifyURL,
        formValue
      );
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function upload(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.post(UploadURL, formData);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function addPortfolio(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.put(AddPortfolioURL, formValue);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getUserPortfolios(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithToken.post(GetUserPortfoliosURL, {});
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}
