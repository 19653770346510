export const UserTypes = {
  PHOTOGRAPHER: 'photographer',
  AGENCY: 'agency',
  JOB_POSTER: 'user',
  ADMIN: 'admin',
};

export const Status = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  HIRED: 'hired',
  REJECTED: 'rejected',
  PENDING_FOR_REVIEW: 'pending for review',
  COMPLETED: 'completed',
  IDLE: 'idle',
  ACTIVE: 'active',
  OPEN: 'open',
  CLOSE: 'close',
  INCOMPLETE: 'incomplete',
  APPROVED: 'approved',
  IN_REVIEW: 'inReview',
  INACTIVE: 'inactive',
};

export const PlanTypes = {
  FREE: 'Free',
  BASIC: 'Basic',
  PREMIUM: 'Premium',
  ENTERPRISE: 'Enterprise',
};
