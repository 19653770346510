import { useForm, useWatch } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Status } from '../../constants/constant';
import { ErrorToaster, SuccessToaster } from '../Toasters/toaster';
import { reportReasons } from '../../dummyData';
import { reportReview } from '../../API/PhotographerApi';

export function ReportModal({ show, setShow }) {
  const [status, setStatus] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setShowOtherInput(selectedReason === 'Other');
  };

  const reportMyReview = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await reportReview(formData);
      SuccessToaster('Review Reported');
      setStatus(Status.IDLE);
      setShow({ status: false });
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };

  const { register, handleSubmit, watch } = useForm();

  //   const selectedReason = useWatch({
  //     control: watch,
  //     name: 'reason', // Replace with your actual field name
  //   });

  const onSubmit = async (data) => {
    const formData = {
      reviewId: show?.data?.reviewId,
      jobId: show?.data?.jobId,
      description: data.description,
      reason: showOtherInput ? data.otherReason : data.reason,
    };

    await reportMyReview(formData);
  };

  return (
    <Modal
      centered
      size="lg"
      backdrop="static"
      show={show}
      onHide={() => setShow({ status: false })}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="col-sm-12 d-flex align-items-center">
              <label className="heading-color ff-heading fw500 mb10">
                Reason
              </label>
              <div
                className="bootselect-multiselect"
                style={{
                  height: '50px',
                  padding: '0px 13px 0px',
                }}>
                <select
                  className="form-control"
                  {...register('reason', {
                    required: 'reason is required',
                  })}
                  onChange={handleReasonChange}
                  style={{
                    marginLeft: '10px',
                    height: '40px',
                    padding: '0px 13px 0px',
                    width: '350px',
                  }}>
                  <option value="" selected disabled>
                    Select Reason
                  </option>
                  {reportReasons?.map((reason, index) => (
                    <option key={index} value={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {showOtherInput && (
            <div className="mt-3">
              <label className="heading-color ff-heading fw500 mb10">
                Other Reason
              </label>
              <input
                type="text"
                {...register('otherReason', {
                  required: 'Other reason is required',
                })}
                className="form-control"
                placeholder="Enter other reason"
              />
            </div>
          )}
          <div className="col-md-12">
            <div className="mb10 mt10">
              <label className="heading-color ff-heading fw500 mb10">
                Description:
              </label>
              <textarea
                {...register('description', {
                  required: 'Description is required',
                })}
                cols="10"
                rows="2"
                placeholder="Write your reason..."
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="ud-btn btn-thm feedback">
              Report
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
