import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jobList, jobDetail, createJob } from './jobApi';

const initialState = {
  status: null,
  jobs: [],
  jobInfo: null,
  error: null,
  isLoading: null,
  message: null,
};

export const postJobAsync = createAsyncThunk(
  'job/postJob',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await createJob(formValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const jobListAsync = createAsyncThunk(
  'job/jobList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await jobList();
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const jobDetailAsync = createAsyncThunk(
  'job/jobDetail',
  async (jobId, { rejectWithValue }) => {
    try {
      const response = await jobDetail(jobId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postJobAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postJobAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.isLoading = false;
        state.message = action.payload.message;
        state.jobInfo = action.payload.data;
        state.error = null;
      })
      .addCase(postJobAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(jobListAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(jobListAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.isLoading = false;
        state.jobs = action.payload.jobs;
        state.error = null;
      })
      .addCase(jobListAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(jobDetailAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(jobDetailAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.jobInfo = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(jobDetailAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export const { clearStatus, clearError, clearMessage } = jobSlice.actions;
export const selectJobInfo = (state) => state.job.jobInfo;
export const selectJobs = (state) => state.job.jobs;
export const selectStatus = (state) => state.job.status;
export const selectError = (state) => state.job.error;
export const selectMessage = (state) => state.job.message;

export default jobSlice.reducer;
