import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';
import Marquee from 'react-fast-marquee';

const ImageSlider = ({ images, isPhotographer }) => {
  return (
    <div className={`${!isPhotographer ? 'img-container' : ''}`}>
      <Marquee>
        {images.map((image, index) => (
          <div key={index} className="carousel-item">
            <img
              src={image}
              alt={`${image} ${index}`}
              className={`${
                isPhotographer
                  ? 'photographer-carousel-image'
                  : 'carousel-image'
              }`}
            />
          </div>
        ))}
      </Marquee>
    </div>
    // <div style={{ marginTop: '20px' }}>
    //   <Slider {...settings}>
    //     {images.map((image, index) => (
    //       <div key={index} className="carousel-item">
    //         <img
    //           src={image}
    //           alt={`Image ${index}`}
    //           className="carousel-image"
    //         />
    //       </div>
    //     ))}
    //   </Slider>
    // </div>
  );
  //   return (
  //     <div
  //       style={{
  //         margin: '20px 0',
  //         borderRadius: '10px !important',
  //         overflow: 'hidden',
  //         width: '100%',
  //         height: 'auto',
  //       }}>
  //       <SimpleImageSlider
  //         className="card_img"
  //         width={375}
  //         height={250}
  //         images={images}
  //         autoPlay={true}
  //         slideDuration={0.9}
  //         autoPlayDelay={1.2}
  //         useGPURender
  //         style={{
  //           borderRadius: '10px !important', // Set the border radius
  //           margin: '20px 0', // Set the margin
  //         }}
  //       />
  //     </div>
  //   );
};

export default ImageSlider;
