import axios from "axios";
import { getToken } from "./getToken";
import { logoutUser } from "./logOut";
import { useLocation } from "react-router-dom";
// 'https://api.topshotphotographer.com'
const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.topshotphotographer.com/";
// "http://localhost:3366";
const axiosWithToken = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  timeout: 30000, // Timeout in milliseconds
});

// Add a request interceptor to attach the token to every request
axiosWithToken.interceptors.request.use(
  async (config) => {
    const TOKEN = getToken();
    if (TOKEN) {
      config.headers["x-access-token"] = TOKEN;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosWithToken.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const { data, status } = error.response || {};
    if (
      [400, 401].includes(status) &&
      typeof window !== "undefined" &&
      ["jwt expired", "user does not exists"].includes(data?.message)
    ) {
      await logoutUser();
      window.location.href = "/";
    }
    if (data) {
      return Promise.reject({ ...data, status });
    }
    return Promise.reject(error.message);
  }
);

const axiosWithoutToken = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  timeout: 10000, // Timeout in milliseconds
});

export { axiosWithToken, axiosWithoutToken };
