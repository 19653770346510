import { Navigate } from 'react-router-dom';
import { getUserInfo } from '../../common/getUserInfo';

function JobPosterPrivateRoute({ children }) {
  const userInfo = getUserInfo();
  if (!userInfo || (userInfo && userInfo.userType !== 'user')) {
    return <Navigate to="/" replace={true} />;
  }
  return children;
}

export default JobPosterPrivateRoute;
