import React, { useEffect, useState } from "react";
import { formatDate } from "../../utils/Dates";
import Loader from "../../utils/Loader";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import { useNavigate } from "react-router-dom";

const NotificationComponent = ({
  showNotificationDropdown,
  notifications,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [translateXPercentage, setTranslateXPercentage] = useState(235);
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();

  useEffect(() => {
    const updateTranslateX = () => {
      const screenWidth = window.innerWidth;
      const widthPercentageMap = {
        1366: 235,
        1517: 275,
        1707: 320,
        1821: 350,
      };
      const percentage = widthPercentageMap[screenWidth] || 405; // Default to 50 if no match

      setTranslateXPercentage(percentage);
    };

    updateTranslateX();
    window.addEventListener("resize", updateTranslateX);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateTranslateX);
    };
  }, []);

  const handleNotificationClick = (redirectURL) => {
    navigate(`/${redirectURL}`);
  };

  return (
    <div
      className={`dropdown-menu ${showNotificationDropdown ? "show" : ""}`}
      style={{
        width: "400px",
        margin: isDesktopOrLaptop ? "20px -80px" : "20px -30px",
        marginTop: isDesktopOrLaptop ? "20px" : "125px",
      }}
    >
      {isLoading ? (
        <Loader />
      ) : notifications.length > 0 ? (
        <div
          className="dboard_notific_dd px30 pt10 pb15"
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          {notifications.map((notification) => (
            <div
              key={notification?._id}
              className="notif_list d-flex align-items-center justify-content-between bdrb1 pb15 mb10"
              style={{ cursor: "pointer" }}
              onClick={() => handleNotificationClick(notification?.redirectURL)}
            >
              <img
                src="https://creativelayers.net/themes/freeio-html/images/resource/notif-4.png"
                alt=""
              />
              <div className="details d-flex flex-column">
                <p className="text mb-0 ml5 fz15">{notification?.message}</p>
                <span className="fz12" style={{ textAlign: "right" }}>
                  {notification?.createdDate &&
                    formatDate(notification?.createdDate)}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-17 text-center text-info py-3">
          No new notifications
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;
