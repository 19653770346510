import React from 'react';
import { aboutUsData } from '../../dummyData';

const AboutUs = () => {
  return (
    <div className="body_content">
      <section className="our-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-title">
                <h2>About Us</h2>
                <p className="text fz16">
                  Welcome to Topshot Photographer, your premier destination for
                  discovering talented local photographers. Whether you're a
                  photographer seeking to showcase your skills or a client in
                  search of exceptional photography, Topshot Photographer is
                  where creativity meets opportunity.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
              <div className="terms_condition_grid text-start">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab">
                    {aboutUsData?.map((item) => (
                      <div className="grids mb30 mb40-md" key={item?.id}>
                        <h4 className="title">{`${item?.id}. ${item?.title}`}</h4>
                        {item.id === 2 ? (
                          <div>
                            <h5>For Photographers:</h5>
                            <ul
                              style={{
                                listStyleType: 'disc',
                                marginLeft: '20px',
                                marginTop: '10px',
                              }}>
                              {item.content
                                .split('For Clients:')[0]
                                .split('-')
                                .filter((value) => value.trim() !== '')
                                .slice(1, 5)
                                .map((value, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      marginBottom: '10px',
                                      color: 'var(--headings-color)',
                                      fontFamily: 'var(--title-font-family)',
                                      fontSize: '16px',
                                      marginRight: '5px',
                                    }}>
                                    - {value.trim()}
                                  </li>
                                ))}
                            </ul>
                            <h5>For Clients:</h5>
                            <ul
                              style={{
                                listStyleType: 'disc',
                                marginLeft: '20px',
                                marginTop: '10px',
                              }}>
                              {item.content
                                .split('For Clients:')[1]
                                .split('-')
                                .filter((value) => value.trim() !== '')
                                .map((value, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      marginBottom: '10px',
                                      color: 'var(--headings-color)',
                                      fontFamily: 'var(--title-font-family)',
                                      fontSize: '16px',
                                      marginRight: '5px',
                                    }}>
                                    - {value.trim()}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        ) : item.title === 'Our Values' ? (
                          <ul
                            style={{
                              listStyleType: 'disc',
                              marginLeft: '20px',
                              marginTop: '10px',
                            }}>
                            {item.content
                              .split('-')
                              .slice(1)
                              .map((value, index) => (
                                <li
                                  key={index}
                                  style={{
                                    marginBottom: '10px',
                                    color: 'var(--headings-color)',
                                    fontFamily: 'var(--title-font-family)',
                                    fontSize: '16px',
                                    marginRight: '5px',
                                  }}>
                                  {value.trim()}
                                </li>
                              ))}
                          </ul>
                        ) : (
                          <p className="mb25 text fz16">
                            {item.content
                              .split('topshotconnect@gmail.com')
                              .map((part, index) => (
                                <span key={index}>
                                  {index === 0 && part}
                                  {index === 1 && (
                                    <a
                                      href="mailto:topshotconnect@gmail.com"
                                      style={{
                                        color: 'green',
                                        textDecoration: 'underline',
                                      }}>
                                      topshotconnect@gmail.com
                                    </a>
                                  )}
                                  {index > 1 && part}
                                </span>
                              ))}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
