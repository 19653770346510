import React from 'react';
import './GoogleButton/GoogleButton.css'; // Import the CSS file
import { UseIsDesktopOrLaptop } from '../Responsive/Responsive';

const GoogleButton = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <button className="google-btn">
      <svg
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="52"
        // style={{ marginLeft: isDesktopOrLaptop ? '-180px' : '-83px' }}
      >
        <rect fill="#fff" height="512" rx="16%" width="512" />
        <path
          d="m386 400c45-42 65-112 53-179h-179v74h102c-4 24-18 44-38 57z"
          fill="#4285f4"
        />
        <path
          d="m90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"
          fill="#34a853"
        />
        <path
          d="m153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"
          fill="#fbbc02"
        />
        <path
          d="m153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"
          fill="#ea4335"
        />
      </svg>
      <span>Continue With Google</span>
    </button>
  );
};

export default GoogleButton;
