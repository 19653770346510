import * as _ from 'lodash';

import { axiosWithToken } from '../common/axiosConfig';
import {
  UserListURL,
  UserDetailURL,
  UserProfileStatusURL,
  JobListsURL,
  JobDetailsURL,
  UpdateJobStatusURL,
  TicketListURL,
  TicketDetailURL,
  UpdateTicketURL,
  RatingReportURL,
  UpdateRatingReportURL,
  ContentListURL,
  UpdateContentURL,
  ReportListsURL,
  UpdateReportStatusURL,
  InsuranceListURL,
  UpdateInsuranceStatusURL,
  QueryListingURL,
  QueryReplyURL,
} from '../constants/apiConstants';

export async function userList(value) {
  return axiosWithToken.post(UserListURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function userDetails(values) {
  return axiosWithToken.post(UserDetailURL, values).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateProfileStatus(formValue) {
  return axiosWithToken.post(UserProfileStatusURL, formValue).then((res) => {
    return _.get(res, 'data.user', {});
  });
}

export async function jobsList(value) {
  return axiosWithToken.post(JobListsURL, value).then((res) => {
    return _.get(res, 'data.jobs', {});
  });
}

export async function fetchJobDetails(jobId) {
  return axiosWithToken.post(JobDetailsURL, jobId).then((res) => {
    return _.get(res, 'data.jobs', {});
  });
}

export async function updateJobStatus(formValue) {
  return axiosWithToken.post(UpdateJobStatusURL, formValue).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function reportsList(value) {
  return axiosWithToken.post(ReportListsURL, value).then((res) => {
    return _.get(res, 'data', {});
  });
}

export async function reportDetails(jobId) {
  return axiosWithToken.post(JobDetailsURL, jobId).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateReportStatus(formValue) {
  return axiosWithToken.post(UpdateReportStatusURL, formValue).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function ticketList() {
  return axiosWithToken.put(TicketListURL).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function ticketDetail(ticketId) {
  return axiosWithToken.post(TicketDetailURL, ticketId).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateTicket(formValue) {
  return axiosWithToken.post(UpdateTicketURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function ratingReport() {
  return axiosWithToken.post(RatingReportURL).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateRatingReport(formValue) {
  return axiosWithToken.post(UpdateRatingReportURL, formValue).then((res) => {
    return _.get(res, 'data.message', {});
  });
}

export async function contentList() {
  return axiosWithToken.put(ContentListURL).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateContent(formValue) {
  return axiosWithToken.post(UpdateContentURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function insuranceList(value) {
  return axiosWithToken.post(InsuranceListURL, value).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function updateInsuranceStatus(formValue) {
  return axiosWithToken
    .post(UpdateInsuranceStatusURL, formValue)
    .then((res) => {
      return _.get(res, 'data.data', {});
    });
}

export async function queryList(formValue) {
  return axiosWithToken.post(QueryListingURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function queryReply(formValue) {
  return axiosWithToken.post(QueryReplyURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}
