import React from 'react';
import ContactUs from '../../components/ContactUs/ContactUs';

const ContactUsPage = () => {
  return (
    <div>
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;
