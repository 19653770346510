import React, { useState, Suspense } from 'react';
import Loader from '../../../utils/Loader';
import { Status } from '../../../constants/constant';

const CreatedJobsTab = React.lazy(() => import('./MyJobTabs/CreatedJobsTab'));
// const RunningJobsTab = React.lazy(() => import('./MyJobTabs/RunningJobsTab'));
const CompletedJobsTab = React.lazy(() =>
  import('./MyJobTabs/CompletedJobsTab')
);

const MyJobs = () => {
  const [activeTab, setActiveTab] = useState(Status.ACTIVE);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            <button
              className={`nav-link ${
                activeTab === Status.ACTIVE ? 'active fw500 ps-0' : 'fw500'
              }`}
              type="button"
              onClick={() => handleTabClick(Status.ACTIVE)}>
              Active Jobs
            </button>
            {/* <button
              className={`nav-link ${
                activeTab === 'running' ? 'active fw500' : 'fw500'
              }`}
              type="button"
              onClick={() => handleTabClick('running')}>
              Running Jobs
            </button> */}
            <button
              className={`nav-link ${
                activeTab === Status.COMPLETED ? 'active fw500' : 'fw500'
              }`}
              type="button"
              onClick={() => handleTabClick(Status.COMPLETED)}>
              Completed Jobs
            </button>
            {/* Add more buttons for other tabs if needed */}
          </div>
        </nav>
        <div className="tab-content">
          {activeTab === Status.ACTIVE && (
            <Suspense fallback={<Loader />}>
              <CreatedJobsTab />
            </Suspense>
          )}
          {/* {activeTab === 'running' && (
            <Suspense fallback={<Loader />}>
              <RunningJobsTab />
            </Suspense>
          )} */}
          {activeTab === Status.COMPLETED && (
            <Suspense fallback={<Loader />}>
              <CompletedJobsTab />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyJobs;
