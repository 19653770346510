// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-width .modal-dialog {
  width: 100%;
  margin: 0 auto;
  max-width: 90vw;
  height: 100%; /* To override the max-width */
}

.custom-modal-width .modal-body {
  max-width: 100%; /* Allow content to take up full width */
  padding: 20px; /* Adjust padding as needed */
}

.required-field {
  color: red;
  margin-left: 0px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/features/user/components/ProfileRegistration/ProfileRegistration.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,eAAe;EACf,YAAY,EAAE,8BAA8B;AAC9C;;AAEA;EACE,eAAe,EAAE,wCAAwC;EACzD,aAAa,EAAE,6BAA6B;AAC9C;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".custom-modal-width .modal-dialog {\n  width: 100%;\n  margin: 0 auto;\n  max-width: 90vw;\n  height: 100%; /* To override the max-width */\n}\n\n.custom-modal-width .modal-body {\n  max-width: 100%; /* Allow content to take up full width */\n  padding: 20px; /* Adjust padding as needed */\n}\n\n.required-field {\n  color: red;\n  margin-left: 0px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
