import React, { useState, Suspense } from 'react';
import { Status } from '../../../../constants/constant';
import InComplete from './Tabs/InComplete';
import Loader from '../../../../utils/Loader';

const InCompleteTab = React.lazy(() => import('./Tabs/InComplete'));

const ManagePhotographers = () => {
  const [activeTab, setActiveTab] = useState(Status.IN_REVIEW);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            <button
              className={`nav-link ${
                activeTab === Status.IN_REVIEW ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.IN_REVIEW)}>
              Under Review
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.INCOMPLETE ? 'active fw500 ps-0' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.INCOMPLETE)}>
              Abandoned
            </button>

            {/* <button
              className={`nav-link ${
                activeTab === Status.APPROVED ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.APPROVED)}>
              Approved
            </button> */}
            <button
              className={`nav-link ${
                activeTab === Status.REJECTED ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.REJECTED)}>
              Rejected
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.ACTIVE ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.ACTIVE)}>
              Active
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.INACTIVE ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.INACTIVE)}>
              Inactive
            </button>
            {/* Add more buttons for other tabs if needed */}
          </div>
        </nav>
        <div className="tab-content">
          {activeTab === Status.INCOMPLETE && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.INCOMPLETE} />
            </Suspense>
          )}
          {activeTab === Status.IN_REVIEW && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.IN_REVIEW} />
            </Suspense>
          )}
          {/* {activeTab === Status.APPROVED && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.APPROVED} />
            </Suspense>
          )} */}
          {activeTab === Status.REJECTED && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.REJECTED} />
            </Suspense>
          )}
          {activeTab === Status.ACTIVE && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.APPROVED} />
            </Suspense>
          )}
          {activeTab === Status.INACTIVE && (
            <Suspense fallback={<Loader />}>
              <InCompleteTab userStatus={Status.INACTIVE} />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePhotographers;
