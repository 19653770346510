import React, { useEffect, useState } from 'react';
import { categories } from '../../../dummyData';
import { useSearchParams } from 'react-router-dom';

const Category = ({ setFilterSkills, filterSkills }) => {
  const [searchParams] = useSearchParams();
  // Extract the 'category' parameter from the search parameters
  const category = searchParams.get('category');
  const [show, setShow] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([category]);

  useEffect(() => {
    if (!!category) {
      setFilterSkills((prev) => [...prev, category]);
    }
  }, [category]);

  const handleCheckboxChange = (category) => {
    const updatedCategories = [...selectedCategories];
    if (updatedCategories.includes(category)) {
      const index = updatedCategories.indexOf(category);
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(category);
    }
    setSelectedCategories(updatedCategories);
    setFilterSkills(updatedCategories); // Update filterSkills with selected categories
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? 'active' : ''}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}>
            Category
          </button>
        </h4>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <div className="card-body card-body px-0 pt-0">
          {categories?.map((category, index) => (
            <div key={index} className="checkbox-style1 mb10">
              <label className="custom_checkbox">
                {category}
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCheckboxChange(category)}
                />
                <span className="checkmark"></span>
                <span className="right-tags"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
