import React from 'react';
import SideMenu from '../../../shared/SideMenu/SideMenu';
import ManageReports from '../../../features/admin/components/ManageReports/ManageReports';
import { UseIsDesktopOrLaptop } from '../../../utils/Responsive/Responsive';

const ManageReportsPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: '-35px' }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? 'dashboard__main' : ''}
          style={{ marginTop: '50px' }}>
          <div
            className="dashboard__content hover-bgc-color"
            style={{ padding: '26px 15px 20px', height: '900px' }}>
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area mt10">
                  <h5>Manage Reports</h5>
                  <p className="text"></p>
                </div>
              </div>
              <ManageReports />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReportsPage;
