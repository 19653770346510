import React from 'react';
import { Button } from 'react-bootstrap';

const ChangePassword = () => {
  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
      <div className="col-lg-12">
        <form className="form-style1">
          <div className="row">
            <div className="col-sm-5">
              <div className="mb20">
                <label className="heading-color ff-heading fw500 mb10">
                  Old Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="********"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="mb20">
              <label className="heading-color ff-heading fw500 mb10">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="********"
              />
            </div>
          </div>
          <div className="col-sm-5">
            <div className="mb20">
              <label className="heading-color ff-heading fw500 mb10">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="********"
              />
            </div>
            <div className="col-md-12">
              <div className="text-start">
                <Button className="ud-btn btn-thm" type="submit">
                  Change Password
                  <i className="fal fa-arrow-right-long"></i>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
