import { axiosWithToken, axiosWithoutToken } from '../../common/axiosConfig';
import {
  RegisterUserURL,
  LoginUserURL,
  LogoutUserURL,
  VerifyOtpURL,
  ForgetPasswordURL,
  ResetPasswordURL,
  GoogleLoginURL,
  MobileSignInVerifyURL,
  MobileSignInURL,
} from '../../constants/apiConstants';
export function userSignup(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(RegisterUserURL, formValue);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function googleLogin(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(GoogleLoginURL, formValue);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function loginUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(LoginUserURL, loginInfo);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyOtp(values) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(VerifyOtpURL, values);
      if (response.status === 200) {
        const data = response.data;
        resolve({ data });
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function mobileLogin(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(MobileSignInURL, loginInfo);
      if (response.status === 200) {
        const data = await response.data;
        resolve(data);
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function mobileVerifyOtp(values) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(
        MobileSignInVerifyURL,
        values
      );
      if (response.status === 200) {
        const data = response.data;
        resolve({ data });
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function forgetPassword(values) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(ForgetPasswordURL, values);
      if (response.status === 200) {
        const data = response.data;
        resolve({ data });
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function resetPassword(formValue) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWithoutToken.post(
        ResetPasswordURL,
        formValue
      );
      if (response.status === 200) {
        const data = response.data;
        resolve({ data });
      } else {
        const error = response;
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
}

// export function signOut() {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axiosWithToken.post(`${LogoutUserURL}`);
//       if (response.ok) {
//         localStorage.clear();
//         resolve({ data: 'success' });
//       } else {
//         const error = await response;
//         reject(error);
//       }
//     } catch (error) {
//       reject(error);
//     }
//   });
// }
