import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { categories } from "../../../../../dummyData";
import { useEffect } from "react";
import CrossIcon from "../../../../../utils/Icons/crossIcon";
import Loader from "../../../../../utils/Loader";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../../../../common/getUserInfo";

const initialValues = {
  fullName: "",
  title: "",
  email: "",
  hourlyRate: "",
  level: "",
  experience: "",
  skills: [],
  bio: "",
  tosConsent: false,
  shareConsent: false,
};

const BasicDetails = ({ formData, handleNext, step, setFormData }) => {
  // const [userInfo, setUserInfo] = useState(null);
  const [status, setStatus] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userInfo = getUserInfo();
  console.log("userInfo", { userInfo, formData });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!formData) return;

    const photographerDetails = formData.photographerDetails || {};

    const isPhotographerDetailsComplete =
      photographerDetails.skills?.length > 0 &&
      photographerDetails.experience &&
      photographerDetails.hourlyRate &&
      photographerDetails.level;

    Object.keys(initialValues).forEach((key) => {
      if (["experience", "hourlyRate", "level"].includes(key)) {
        const value = isPhotographerDetailsComplete
          ? photographerDetails[key]
          : formData[key];
        setValue(key, value);
      } else if (key === "skills") {
        const skills = isPhotographerDetailsComplete
          ? photographerDetails.skills
          : formData.skills;
        setSelectedOptions(skills || []);
      } else {
        setValue(key, formData[key]);
      }
    });
  }, [formData, setValue]);

  // const handleInputChange = (event, fieldName) => {
  //   const inputValue = event.target.value;
  //   const words = inputValue.split(/\s+/).filter((word) => word !== '');
  //   setWordCount(words.length);
  //   if (fieldName === 'bio' && words.length > 500) {
  //     const truncatedText = words.slice(0, 500).join(' ');
  //     event.target.value = truncatedText;
  //   }
  //   if (fieldName === 'title' && words.length > 100) {
  //     const truncatedText = words.slice(0, 100).join(' ');
  //     event.target.value = truncatedText;
  //   }
  // };
  // const handleImageClick = () => {
  //   fileInputRef.current.click();
  // };

  const handleChange = (e) => {
    clearErrors("skills");
    const selectedValue = e.target.value;
    if (!(selectedOptions || []).includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }
  };
  const handleRemove = (e, valueToRemove) => {
    e.preventDefault();
    const updatedOptions = selectedOptions.filter(
      (value) => value !== valueToRemove
    );
    setSelectedOptions(updatedOptions);
  };

  const handleBasicDetailsSubmit = async (data) => {
    if (selectedOptions.length === 0) {
      setError("skills", { message: "Skills are Required" });
    } else {
      const formValue = {
        fullName: userInfo?.fullName,
        email: userInfo?.email,
        location: data?.location,
        skills: selectedOptions,
        level: data?.level,
        experience: data?.experience,
        bio: data?.bio,
        hourlyRate: data?.hourlyRate,
        title: data?.title,
      };
      setFormData({ ...data, ...formData, ...formValue });
      handleNext();
    }
  };

  return (
    <div>
      <h4>Basic Details</h4>
      <hr className="opacity-100 mb60 mt10" />
      {status === "loading" ? (
        <Loader />
      ) : (
        <div className="col-lg-10 mt10">
          <form
            className="form-style1"
            style={{ marginTop: "-40px" }}
            noValidate
            onSubmit={handleSubmit(handleBasicDetailsSubmit)}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="mb10">
                  <label className="heading-color ff-heading fw500 mb10">
                    Title
                    <span className="required-field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("title", {
                      required: "Title is required",
                      maxLength: {
                        value: 70,
                        message:
                          "Title length should be less than 70 characters",
                      },
                    })}
                    // defaultValue={formData?.title}
                    // onChange={(e) => handleInputChange(e, 'title')}
                    cols="30"
                    rows="6"
                    placeholder="Ex: Senior Wedding Photographer"
                  />
                  {errors.title && (
                    <p className="text-danger">{errors.title.message}</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20">
                  <label className="heading-color ff-heading fw500 mb10">
                    Full Name <span className="required-field">*</span>
                  </label>
                  <input
                    {...register("fullName", {
                      required: "Full Name is required",
                      maxLength: {
                        value: 20,
                        message:
                          "Full Name length should be less than 20 characters",
                      },
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name"
                  />
                  {errors.fullName && (
                    <p className="text-danger">{errors.fullName.message}</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20 align-items-center">
                  <label className="heading-color ff-heading fw500 mb10">
                    Email
                  </label>
                  <div className="input-group flex-grow-1">
                    <input
                      value={userInfo?.email}
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Years in business
                      <span className="required-field">*</span>
                    </label>
                    <input
                      {...register("experience", {
                        required: "Experience is required",
                        maxLength: {
                          value: 2,
                          message: "Please enter valid experience",
                        },
                      })}
                      type="number"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder=" Enter Your Experience"
                    />
                    {errors.experience && (
                      <p className="text-danger">{errors.experience.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Hourly Rate
                      <span className="required-field">*</span>
                    </label>
                    <input
                      {...register("hourlyRate", {
                        required: "Hourly rate is required",
                        maxLength: {
                          value: 4,
                          message: "Please enter valid hourly rate",
                        },
                      })}
                      type="number"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder="Enter Hourly Rate"
                    />
                    {errors.hourlyRate && (
                      <p className="text-danger">{errors.hourlyRate.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10 fz16">
                      Photography Level{" "}
                      <span className="required-field">*</span>
                    </label>
                    <div className="bootselect-multiselect">
                      <select
                        className="form-control"
                        name="level"
                        id="level"
                        {...register("level", {
                          required: "Level is required",
                        })}
                      >
                        <option value="" disabled>
                          Choose Level
                        </option>
                        <option value="entry">Entry</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="expert">Expert</option>
                      </select>
                      {errors.level && (
                        <p className="text-danger">{errors.level.message}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Select Category (Upto 3)
                      <span className="required-field">*</span>
                    </label>
                    <div className="bootselect-multiselect">
                      <select
                        className="form-control"
                        onChange={handleChange}
                        // disabled={selectedOptions?.length >= 3}
                        value=""
                        required
                      >
                        <option value="" disabled>
                          Choose the categories you have experience in.
                        </option>
                        {categories
                          .filter(
                            (category) => !selectedOptions?.includes(category)
                          )
                          .map((category, index) => (
                            <option key={index} value={category}>
                              {category}
                            </option>
                          ))}
                      </select>
                      {errors.skills && (
                        <p className="text-danger">{errors.skills.message}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb10">
                  <div className="skill-tags border-black d-flex flex-wrap">
                    {selectedOptions?.map((option, index) => (
                      <div
                        className="position-relative d-flex"
                        style={{
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <span className="tag position-relative">{option}</span>
                        <div onClick={(e) => handleRemove(e, option)}>
                          <CrossIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt10">
                <div className="mb10">
                  <label className="heading-color ff-heading fw500 mb10">
                    Introduction
                    <span className="required-field">*</span>
                  </label>
                  <textarea
                    {...register("bio", {
                      required: "Introduction is required",
                      maxLength: {
                        value: 500,
                        message:
                          "Introduction length should be less than 500 characters",
                      },
                    })}
                    cols="30"
                    rows="6"
                    placeholder="Tell us about yourself..."
                  />
                  {errors.bio && (
                    <p className="text-danger">{errors.bio.message}</p>
                  )}
                </div>
              </div>
              <label className="custom_checkbox fz14 ff-heading">
                By checking this box, you give us permission to share your
                portfolio images on our social media platforms.
                <input type="checkbox" {...register("shareConsent")} />
                {errors.shareConsent && (
                  <p className="text-danger">{errors.shareConsent.message}</p>
                )}
                <span className="checkmark"></span>
              </label>
              <label className="custom_checkbox fz14 ff-heading">
                Yes, I understand and agree to the Topshot Photographer
                conditions, including the{" "}
                <Link to="/terms-and-conditions" className="text-thm">
                  terms and conditions
                </Link>{" "}
                and{" "}
                <Link to="privacy-policy" className="text-thm">
                  {" "}
                  privacy policy
                </Link>{" "}
                .
                <input
                  type="checkbox"
                  {...register("tosConsent", {
                    required: "You must agree to the terms and conditions",
                  })}
                />
                {errors.tosConsent && (
                  <p className="text-danger">{errors.tosConsent.message}</p>
                )}
                <span className="checkmark"></span>
              </label>
              <div className="col-md-12 mt10">
                <div className="text-start">
                  <Button
                    className="ud-btn btn-thm"
                    type="submit"
                    // onClick={handleNextClick}
                    //   onClick={handleNext}
                    // disabled={userInfo?.profileStatus === 'pending for review'}
                  >
                    {step === 1 ? "Next" : "Submit"}
                    <i className="fal fa-arrow-right-long"></i>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BasicDetails;
