import React, { useState, Suspense } from 'react';
import { Status } from '../../../../constants/constant';
import Loader from '../../../../utils/Loader';

const TransactionsTable = React.lazy(() => import('./TransactionsListing'));

const ManageTransactions = () => {
  const [activeTab, setActiveTab] = useState(Status.PENDING);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            <button
              className={`nav-link ${
                activeTab === Status.PENDING ? 'active fw500' : 'fw500 fs15'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.PENDING)}>
              Pending
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.COMPLETED ? 'active fw500 ps-0' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.COMPLETED)}>
              Completed
            </button>

            <button
              className={`nav-link ${
                activeTab === Status.REJECTED ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.REJECTED)}>
              Rejected
            </button>
          </div>
        </nav>
        <div className="tab-content">
          {activeTab === Status.PENDING && (
            <Suspense fallback={<Loader />}>
              <TransactionsTable userStatus={Status.PENDING} />
            </Suspense>
          )}
          {activeTab === Status.COMPLETED && (
            <Suspense fallback={<Loader />}>
              <TransactionsTable userStatus={Status.COMPLETED} />
            </Suspense>
          )}
          {activeTab === Status.REJECTED && (
            <Suspense fallback={<Loader />}>
              <TransactionsTable userStatus={Status.REJECTED} />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTransactions;
