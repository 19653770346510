import * as _ from 'lodash';

import { axiosWithToken, axiosWithoutToken } from '../common/axiosConfig';
import {
  CreateChatURL,
  GetAllChatURL,
  GetChatCountURL,
  GetOneChatDetailsURL,
} from '../constants/apiConstants';

export function createChat(formValue) {
  return axiosWithToken.post(CreateChatURL, formValue).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export function getAllChat() {
  return axiosWithToken.post(GetAllChatURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export function getOneChat(chatId) {
  return axiosWithToken
    .post(GetOneChatDetailsURL, { chatId: chatId })
    .then((res) => {
      return _.get(res, 'data.data', {});
    });
}

export function getChatCount(chatId) {
  return axiosWithToken.post(GetChatCountURL).then((res) => {
    return _.get(res, 'data.nCount', {});
  });
}
