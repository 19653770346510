import React, { useState, useEffect } from 'react';
import { RangeSlider } from 'rsuite';
import 'rsuite/dist/rsuite.css';

const Price = ({ setFilterPrice }) => {
  const [show, setShow] = useState(true);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(1000);

  useEffect(() => {
    setFilterPrice({ min: minValue || 1, max: maxValue || 1000 });
  }, [minValue, maxValue, setFilterPrice]);

  const handleRanges = (value) => {
    setMinValue(value[0]);
    setMaxValue(value[1]);
  };

  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setMinValue(value);
    if (value > maxValue) {
      setMaxValue(value);
    }
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setMaxValue(value);
    if (value < minValue) {
      setMinValue(value);
    }
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? 'active' : ''}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}>
            Price
          </button>
        </h4>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{
          width: '16rem',
          padding: '20px',
        }}>
        <RangeSlider
          min={1}
          max={100000}
          defaultValue={[1, 100000]}
          onChange={handleRanges}
          className="custom-slider"
        />
        <br />
        <div className="text-center">
          <input
            type="number"
            className="amount"
            value={minValue}
            style={{ width: '90px', height: '41px' }}
            onChange={handleMinRangeChange}
          />
          <span className="fa-sharp fa-solid fa-minus mx-2 dark-color"></span>
          <input
            type="number"
            className="amount2"
            value={maxValue}
            style={{ width: '90px', height: '41px' }}
            onChange={handleMaxRangeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Price;
