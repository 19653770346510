import React from 'react';
import JobsListing from '../../features/job/components/JobsListing';
const JobsListingPage = () => {
  return (
    <div>
      <JobsListing />
    </div>
  );
};

export default JobsListingPage;
