import React from 'react';

const AllFilterActionButton = ({ handleClick }) => {
  return (
    <button
      type="button"
      className="open-btn filter-btn-left"
      onClick={handleClick}>
      {' '}
      <img
        className="me-2"
        src="https://creativelayers.net/themes/freeio-html/images/icon/all-filter-icon.svg"
        alt=""
      />{' '}
      All Filter
    </button>
  );
};

export default AllFilterActionButton;
