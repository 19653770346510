import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addPortfolio,
  fetchMyDetail,
  getUserPortfolios,
  updateUserDetails,
  updateUserEmailStart,
  updateUserEmailVerify,
  upload,
} from './userApi';

const initialState = {
  status: null,
  userInfo: null,
  error: null,
  isLoading: null,
  message: null,
  userPortfolios: [],
};

export const myDetailAsync = createAsyncThunk(
  'user/myDetail',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchMyDetail();
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  'user/updateUser',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await updateUserDetails(formValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const updateUserEmailStartAsync = createAsyncThunk(
  'user/updateUserEmailStart',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await updateUserEmailStart(formValue);
      return response.message;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const updateUserEmailVerifyAsync = createAsyncThunk(
  'user/updateUserEmailVerify',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await updateUserEmailVerify(formValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const addPortfolioAsync = createAsyncThunk(
  'user/addPortfolio',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addPortfolio(formData);
      return response.message;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const getUserPortfoliosAsync = createAsyncThunk(
  'user/getUserPortfolios',
  async ({ rejectWithValue }) => {
    try {
      const response = await getUserPortfolios();
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data.message);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(myDetailAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(myDetailAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.isLoading = false;
        state.userInfo = action.payload;
        state.error = null;
      })
      .addCase(myDetailAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.userInfo = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserEmailStartAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserEmailStartAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateUserEmailStartAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserEmailVerifyAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserEmailVerifyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.userInfo = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateUserEmailVerifyAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addPortfolioAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPortfolioAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.message = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addPortfolioAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserPortfoliosAsync.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserPortfoliosAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.userPortfolios = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getUserPortfoliosAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export const { clearStatus, clearError, clearMessage } = userSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo;
export const selectStatus = (state) => state.user.status;
export const selectError = (state) => state.user.error;
export const selectMessage = (state) => state.user.message;
export const selectUserPortfolios = (state) => state.user.userPortfolios;

export default userSlice.reducer;
