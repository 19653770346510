import React from 'react';
import { termsAndConditions } from '../../dummyData';

const TermAndConditions = () => {
  return (
    <div className="body_content">
      <section className="our-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-title">
                <h2>Terms and Conditions</h2>
                <p className="text">
                  Welcome to Topshot Photographer! These Terms and Conditions
                  ("Terms") govern your use of our platform. By accessing or
                  using our platform, you agree to comply with these Terms.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-2">
              <div className="terms_condition_widget mb30-sm">
                <div className="widget_list">
                  <nav>
                    <div
                      className="nav nav-tabs text-start"
                      id="nav-tab"
                      role="tablist">
                      <button
                        className="nav-link text-start"
                        id="nav-accountpayment-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-accountpayment"
                        type="button"
                        role="tab"
                        aria-controls="nav-accountpayment"
                        aria-selected="true">
                        Account & Payments
                      </button>
                      {/* <button
                        className="nav-link text-start"
                        id="nav-manageother-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-manageother"
                        type="button"
                        role="tab"
                        aria-controls="nav-manageother"
                        aria-selected="false">
                        Manage Orders
                      </button>
                      <button
                        className="nav-link text-start"
                        id="nav-returrefund-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-returrefund"
                        type="button"
                        role="tab"
                        aria-controls="nav-returrefund"
                        aria-selected="false">
                        Returns & Refunds
                      </button>
                      <button
                        className="nav-link text-start"
                        id="nav-covid19-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-covid19"
                        type="button"
                        role="tab"
                        aria-controls="nav-covid19"
                        aria-selected="false">
                        COVID-19
                      </button>
                      <button
                        className="nav-link text-start active"
                        id="nav-other-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-other"
                        type="button"
                        role="tab"
                        aria-controls="nav-other"
                        aria-selected="false">
                        Other
                      </button> */}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
              <div className="terms_condition_grid text-start">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab">
                    {termsAndConditions?.map((item) => (
                      <div className="grids mb30 mb40-md" key={item?.id}>
                        <h4 className="title">{`${item?.id}. ${item?.title}`}</h4>
                        <p className="mb25 text fz15">{item?.description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermAndConditions;
