import React from 'react';
import { Empty } from 'antd';

export const EmptyComponent = ({ description, type }) => {
  let customDescriptionStyle;
  if (type) {
    customDescriptionStyle = {
      fontSize: '18px',
      alignItem: 'center',
    };
  } else {
    customDescriptionStyle = {
      fontSize: '18px',
      height: '600px', // Adjust the font size here as needed
    };
  }
  return <Empty description={description} style={customDescriptionStyle} />;
};
