/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { getUserInfo } from '../../common/getUserInfo';
import { selectAccessToken } from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { UseIsDesktopOrLaptop } from '../../utils/Responsive/Responsive';
import { Link } from 'react-router-dom';
import { getToken } from '../../common/getToken';

const Footer = () => {
  const token = useSelector(selectAccessToken) || getToken();
  const userInfo = getUserInfo();
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();

  return (
    <>
      {token || userInfo ? (
        <>
          <footer
            className={
              isDesktopOrLaptop
                ? 'dashboard_footer w-70 pt30 ml90 pl200'
                : 'dashboard_footer pb30 pt30'
            }>
            <div className="container ">
              <div className="row align-items-center justify-content-center justify-content-md-between">
                <div className="col-lg-8">
                  <div className="copyright-widget">
                    <p className="mb-md-0">
                      © TSP {new Date().getFullYear()}. All rights reserved.
                    </p>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="footer_bottom_right_btns at-home8 text-center text-lg-end"></div>
                </div>
              </div>
            </div>
          </footer>
          <a className="scrollToHome" href="">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      ) : (
        <>
          <section className="footer-style1 at-home5 pt25 pb-0">
            <div className="container">
              <div className="row bdrb1 pb10 mb60">
                <div className="col-md-7">
                  <div className="d-block text-center text-md-start justify-content-center justify-content-md-start d-md-flex align-items-center mb-3 mb-md-0">
                    <Link
                      className="fz17 fw500 mr15-md mr30"
                      to="/terms-and-conditions">
                      Terms of Service
                    </Link>
                    <Link
                      className="fz17 fw500 mr15-md mr30"
                      to="/privacy-policy">
                      Privacy Policy
                    </Link>
                    <Link className="fz17 fw500 mr15-md mr30" to="/faqs">
                      FAQs
                    </Link>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="social-widget text-center text-md-end">
                    <div className="social-style1 light-style2">
                      <a className="me-2 fw500 fz17" href="">
                        Follow us
                      </a>
                      <Link
                        to="https://www.facebook.com/topshotphotographers/"
                        target="_blank">
                        <i className="fab fa-facebook-f list-inline-item"></i>
                      </Link>
                      {/* <Link href="">
                        <i className="fab fa-twitter list-inline-item"></i>
                      </Link> */}
                      <Link
                        to="https://www.instagram.com/topshotphotographer/"
                        target="_blank">
                        <i className="fab fa-instagram list-inline-item"></i>
                      </Link>
                      <Link
                        to="https://www.linkedin.com/company/top-shot-photographer/"
                        target="_blank">
                        <i className="fab fa-linkedin-in list-inline-item"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-3">
                  <div className="link-style1 light-style at-home8 mb-4 mb-sm-5">
                    <h5 className="mb15">About</h5>
                    <div className="link-list">
                      <Link to="/about" className="fz16">
                        About Us
                      </Link>
                      <Link to="/terms-and-conditions" className="fz16">
                        Terms of Service
                      </Link>
                      <Link to="/privacy-policy" className="fz16">
                        Privacy Policy
                      </Link>
                      <Link to="/faqs" className="fz16">
                        FAQs
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="link-style1 light-style at-home8 mb-4 mb-sm-5">
                    <h5 className="mb15">Categories</h5>
                    <ul className="ps-0">
                      <li>
                        <Link
                          to="/photographers?category=Concert"
                          className="fz16">
                          Concert
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/photographers?category=Wedding"
                          className="fz16">
                          Wedding
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/photographers?category=Event"
                          className="fz16">
                          Event
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/photographers?category=Maternity"
                          className="fz16">
                          Maternity
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/photographers?category=Engagement"
                          className="fz16">
                          Engagement
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/photographers?category=Headshots"
                          className="fz16">
                          Headshots
                        </Link>
                      </li>
                      <li>
                        <Link to="/photographers" className="fz16">
                          And More
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="link-style1 light-style at-home8 mb-4 mb-sm-5">
                    <h5 className="mb15">Support</h5>
                    <ul className="ps-0">
                      <li>
                        <Link to="/contact" className="fz16">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="footer-widget">
                    {/* <div className="footer-widget mb-4 mb-sm-5">
                      <div className="mailchimp-widget">
                        <h5 className="title mb20">Subscribe</h5>
                        <div className="mailchimp-style1 at-home7">
                          <input
                            type="email"
                            className="form-control bg-white"
                            placeholder="Your email address"
                          />
                          <button type="submit">Send</button>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="app-widget mb-4 mb-sm-5">
                      <h5 className="title mb20">Apps</h5>
                      <div className="row mb-4 mb-lg-5">
                        <div className="col-lg-12">
                          <a
                            className="app-list light-style d-flex align-items-center mb10"
                            href="">
                            <i className="fab fa-apple fz17 mr15"></i>
                            <h6 className="app-title fz15 fw400 mb-0">
                              iOS App
                            </h6>
                          </a>
                          <a
                            className="app-list light-style d-flex align-items-center"
                            href="">
                            <i className="fab fa-google-play fz15 mr15"></i>
                            <h6 className="app-title fz15 fw400 mb-0">
                              Android App
                            </h6>
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="container bdrt1 py-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="text-center text-lg-start">
                    <p className="copyright-text mb-2 mb-md0 text-dark-light ff-heading">
                      © TopShot. {new Date().getFullYear()}. All rights
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <a className="scrollToHome" href="">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      )}
    </>
  );
};

export default Footer;
