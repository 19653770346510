import * as React from 'react';
function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M4 6L8 10L12 6"
        stroke="#1b75bb"
        stroke-width="1.5"
        stroke-linecap="round"
        {...props}
      />
    </svg>
  );
}

export default ArrowDown;
