import React, { useRef } from "react";
import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../shared/Toasters/toaster";
import { categories } from "../../../../../dummyData";
import EditModal from "../../../../../components/model/EditForm";
import { useEffect } from "react";
import {
  getPortfolios,
  myDetail,
  updateUser,
  deleteCategory,
} from "../../../../../API/UserApi";
import Loader from "../../../../../utils/Loader";
import { v4 as uuidv4 } from "uuid";
import { upload } from "../../../userApi";
import { Status } from "../../../../../constants/constant";
import MapComponent from "../../../../../shared/MapComponent/MapComponent";
import { CategoryWarningModal } from "../../../../../shared/Modal/CategoryWarningModal";
import Swal from "sweetalert2";

const initialValues = {
  fullName: "",
  title: "",
  email: "",
  phoneNumber: "",
  hourlyRate: "",
  level: "",
  experience: "",
  skills: [],
  bio: "",
  location: "",
  isBusinessInsured: false,
  isInsuranceVerified: false,
  isStudioSpaceAvailable: false,
  isVideographyAvailable: false,
  tosConsent: false,
  shareConsent: false,
};

const BasicDetailsTab = () => {
  const guid = uuidv4();
  const [userInfo, setUserInfo] = useState(null);
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isCategoryWarningOpen, setIsCategoryWarningOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(
    userInfo?.photographerDetails?.skills || []
  );
  const [isEditable, setIsEditable] = useState(false);
  const [editType, setEditType] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const fileInputRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  useEffect(() => {
    if (userInfo) {
      Object.keys(initialValues).forEach((key) => {
        if (["experience", "hourlyRate", "level", "skills"].includes(key)) {
          setValue(key, userInfo?.photographerDetails?.[key]);
          setSelectedOptions(userInfo?.photographerDetails?.skills);
        } else {
          setValue(key, userInfo[key]);
        }
      });
    }
  }, [userInfo, setValue]);

  const {
    usePlanDetails,
    profileStatus,
    businessInsuranceFile,
    email,
    isBusinessInsured,
    isInsuranceVerified,
    mobile,
    profilePicture,
    userType,
  } = userInfo || {};
  const { categoryLimit } = usePlanDetails || {};
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (!selectedOptions.includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }
  };

  const deletePortfolio = (category) => {
    const swalLoading = Swal.fire({
      title: "Please Wait",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });
    try {
      const { message } = deleteCategory({ category });
      SuccessToaster(message);
    } catch (error) {
      ErrorToaster("Something went wrong.");
    } finally {
      swalLoading.close();
    }
  };

  const deleteSelectedCategory = async (valueToRemove) => {
    const updatedOptions = selectedOptions.filter(
      (value) => value !== valueToRemove
    );
    setSelectedOptions(updatedOptions);
    await deletePortfolio(valueToRemove);
    setIsCategoryWarningOpen(false);
  };

  const handleCategoryDelete = async (e, valueToRemove) => {
    e.preventDefault();
    try {
      const res = await getPortfolios();
      const isPortfolioAvailable = res?.find(
        (portfolio) => portfolio.category === valueToRemove
      );

      if (isPortfolioAvailable) {
        setSelectedCategory(valueToRemove);
        setIsCategoryWarningOpen(true);
      } else {
        deleteSelectedCategory(valueToRemove);
      }
    } catch (error) {
      ErrorToaster("Something went wrong.");
    }
  };

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      setUserInfo(data);
      localStorage.setItem("currentUser", JSON.stringify(data));
      setSelectedOptions(data?.photographerDetails?.skills);
      setStatus(Status.IDLE);
    } catch (error) {
      console.error("Error while getting user details", error);
      setStatus(Status.REJECTED);
    }
  };

  useEffect(() => {
    myDetails();
  }, []);

  const updateUserDetails = async (data) => {
    const updateData = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});
    const formValue = {
      ...updateData,
      location: data?.location,
      skills: selectedOptions,
      profilePicture: data?.profilePicture,
      businessInsuranceFile: data?.businessInsuranceFile,
      bio: data?.bio,
      title: data?.title,
    };
    setStatus(Status.PENDING);
    try {
      const data = await updateUser(formValue);
      SuccessToaster(data);
      setStatus(Status.IDLE);
      await myDetails();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  const handleEditClick = (type) => {
    setEditType(type);
    setIsEditable(true);
  };
  const handleFileChange = async (e, type) => {
    const formData = new FormData();
    setIsLoading(true);
    formData.append("file", e.target.files[0]); // Append the file
    formData.append("guid", guid); // Append the guid
    type === "profile"
      ? formData.append("mediaType", "profile")
      : formData.append("mediaType", "business_insurance");
    try {
      const res = await upload(formData);
      if (res?.status === 200 && res?.data) {
        SuccessToaster(res?.message);
        type === "profile"
          ? updateUserDetails({ profilePicture: res?.data?.high })
          : updateUserDetails({ businessInsuranceFile: res?.data?.high });
        setIsLoading(false);
      }
    } catch (err) {
      ErrorToaster(err?.response?.data?.message);
    }
  };

  const handleInputChange = (event, fieldName) => {
    const inputValue = event.target.value;
    const words = inputValue.split(/\s+/).filter((word) => word !== "");
    setWordCount(words.length);
    if (fieldName === "bio" && words.length > 500) {
      const truncatedText = words.slice(0, 500).join(" ");
      event.target.value = truncatedText;
    }
    if (fieldName === "title" && words.length > 100) {
      const truncatedText = words.slice(0, 100).join(" ");
      event.target.value = truncatedText;
    }
  };

  return (
    <>
      {/* {isCategoryWarningOpen && (
        <CategoryWarningModal
          title={
            isCategoryWarningOpen.type === 'maxImgLimit'
              ? 'Upload Limit Reached'
              : 'Information'
          }
          show={isCategoryWarningOpen.status}
          onHide={() => setIsCategoryWarningOpen(false)}
          message={
            isCategoryWarningOpen.type === 'minImgLimit'
              ? 'You must have at least 5 images in the category. Please upload new images to the category if you want to delete any existing ones.'
              : 'Your current plan has reached its image upload limit. To upload more images, please consider upgrading your plan.'
          }
          secondaryButtonText={
            isCategoryWarningOpen.type === 'maxImgLimit' ? 'Not Now' : 'Close'
          }
          primaryButtonText={
            isCategoryWarningOpen.type === 'maxImgLimit' && 'Upgrade Plan'
          }
          handlePrimaryButtonClick={
            isCategoryWarningOpen.type === 'maxImgLimit' &&
            handleUpgradePlanClick
          }
        />
      )} */}
      {isCategoryWarningOpen && (
        <CategoryWarningModal
          show={isCategoryWarningOpen}
          onHide={() => setIsCategoryWarningOpen(false)}
          message="Are you sure you want to delete this category?. if you delete this category your portfolio will also be deleted"
          primaryButtonText="Delete"
          secondaryButtonText="Close"
          handlePrimaryButtonClick={() =>
            deleteSelectedCategory(selectedCategory)
          }
        />
      )}
      <EditModal
        editType={editType}
        showModal={isEditable}
        setShowModal={setIsEditable}
      />
      {status === Status.PENDING || userInfo === null ? (
        <Loader type="pending" isUploading={false} />
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="col-xl-12">
              <div className="profile-box d-sm-flex align-items-center mb30">
                <div className="profile-img mb20-sm">
                  {isLoading ? (
                    <Loader type="img" isUploading={true} />
                  ) : (
                    <img
                      src={
                        profilePicture
                          ? profilePicture
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT18iwsdCCbBfpa50-5BmNa_m_BX087_x1oWQ&usqp=CAU"
                      }
                      className="rounded-circle"
                      style={{
                        width: "80px",
                        height: "80px", // Set a fixed height
                        objectFit: "cover", // Maintain aspect ratio and cover the container
                        borderRadius: "50%",
                      }}
                      alt="user.png"
                    />
                  )}
                </div>
                <div className="profile-content ml20 ml0-xs">
                  <div className="d-flex align-items-center my-3">
                    {/* <a href="" className="tag-delt text-thm2">
                      <span className="flaticon-delete text-thm2"></span>
                    </a> */}
                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                      <input
                        type="file"
                        id="fileInput"
                        className=" d-none"
                        ref={fileInputRef}
                        onChange={(e) => handleFileChange(e, "profile")}
                      />
                      <span className="upload-btn ml10">Upload Profile</span>
                    </label>
                  </div>
                  <p className="text mb-0">
                    Max file size is 1MB, Minimum dimension: 330x300 And
                    Suitable files are .jpg & .png
                  </p>
                </div>
              </div>
              <div>
                <p className="heading-color ff-heading fw500 fz16">
                  Profile Status:{" "}
                  <b
                    className="fz16 text-capitalize"
                    style={{
                      color:
                        profileStatus === Status.PENDING_FOR_REVIEW
                          ? ""
                          : "#5BBB7B",
                    }}
                  >
                    {profileStatus === Status.PENDING_FOR_REVIEW
                      ? "Your profile is currently under review. You may make changes to your profile once we have completed the review process and taken appropriate action."
                      : profileStatus}
                  </b>
                </p>
              </div>
            </div>
            <div className="ps-widget bgc-white bdrs0 p10 mb30 overflow-hidden position-relative">
              <div className="col-lg-10 mt10">
                <form
                  className="form-style1"
                  noValidate
                  onSubmit={handleSubmit(updateUserDetails)}
                >
                  <div className="row">
                    {(userType === "photographer" || userType === "agency") && (
                      <>
                        <div className="col-md-6">
                          <div className="mb10">
                            <label className="heading-color ff-heading fw500 mb10 fz16">
                              Title
                              <span className="required-field">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("title", {
                                required: "Title is required",
                                maxLength: {
                                  value: 70,
                                  message:
                                    "Title length should be less than 70 characters",
                                },
                              })}
                              cols="30"
                              rows="6"
                              placeholder="Enter Title"
                            />
                            {errors.title && (
                              <p className="text-danger">
                                {errors.title.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10 fz16">
                          Full Name <span className="required-field">*</span>
                        </label>
                        <input
                          {...register("fullName", {
                            required: "Full Name is required",
                            maxLength: {
                              value: 20,
                              message:
                                "Full Name length should be less than 20 characters",
                            },
                          })}
                          // defaultValue={fullName}
                          type="text"
                          className="form-control"
                          placeholder="Enter Full Name"
                        />
                        {errors.fullName && (
                          <p className="text-danger">
                            {errors.fullName.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20 align-items-center">
                        <label className="heading-color ff-heading fw500 mb10 fz16">
                          Email <span className="required-field">*</span>
                        </label>
                        <div className="input-group flex-grow-1">
                          <input
                            value={email}
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            disabled
                          />
                          {userType !== "admin" &&
                            profileStatus !== "rejected" && (
                              <div className="input-group-append mt20">
                                <span className="input-group-text bg-transparent border-0">
                                  <i
                                    className="fa-regular fa-pen-to-square fa-lg"
                                    onClick={() => handleEditClick("email")}
                                  ></i>
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <label className="heading-color ff-heading fw500 mb10 fz16">
                          Mobile Number
                        </label>
                        <div className="input-group flex-grow-1">
                          <input
                            {...register("mobile")}
                            value={mobile}
                            type="number"
                            pattern="[0-9]*"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            disabled
                          />
                          {userType !== "admin" &&
                            profileStatus !== "rejected" && (
                              <div className="input-group-append mt20">
                                <span className="input-group-text bg-transparent border-0">
                                  <i
                                    className="fa-regular fa-pen-to-square fa-lg"
                                    onClick={() => handleEditClick("mobile")}
                                  ></i>
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb20">
                        <div className="form-style1">
                          <label className="heading-color ff-heading fw500 mb10 fz16">
                            Location <span className="required-field">*</span>
                          </label>
                          <MapComponent
                            name="location"
                            register={register}
                            setValue={setValue}
                            required
                            placeholder="Enter Location"
                          />
                          {errors.location && (
                            <p className="text-danger">
                              {errors.location.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {userType === "photographer" || userType === "agency" ? (
                      <>
                        <div className="col-sm-6">
                          <div className="mb20">
                            <div className="form-style1">
                              <label className="heading-color ff-heading fw500 mb10 fz16">
                                Photography Level{" "}
                                <span className="required-field">*</span>
                              </label>
                              <div className="bootselect-multiselect">
                                <select
                                  className="form-control"
                                  name="level"
                                  id="level"
                                  // defaultValue={level}
                                  {...register("level", {
                                    required: "Level is required",
                                  })}
                                >
                                  {errors.level && (
                                    <p className="text-danger">
                                      {errors.level.message}
                                    </p>
                                  )}
                                  <option value="" disabled>
                                    Choose Level
                                  </option>
                                  <option value="entry">Entry</option>
                                  <option value="intermediate">
                                    Intermediate
                                  </option>
                                  <option value="expert">Expert</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb20">
                            <div className="form-style1">
                              <label className="heading-color ff-heading fw500 mb10 fz16">
                                Years in business{" "}
                                <span className="required-field">*</span>
                              </label>
                              <input
                                {...register("experience", {
                                  required: "Experience is required",
                                  maxLength: {
                                    value: 2,
                                    message: "Please enter valid experience",
                                  },
                                })}
                                // defaultValue={experience || ''}
                                type="number"
                                pattern="[0-9]*"
                                className="form-control"
                                placeholder=" Enter Your Experience"
                              />
                              {errors.experience && (
                                <p className="text-danger">
                                  {errors.experience.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb20">
                            <div className="form-style1">
                              <label className="heading-color ff-heading fw500 mb10 fz16">
                                Hourly Rate{" "}
                                <span className="required-field">*</span>
                              </label>
                              <input
                                {...register("hourlyRate", {
                                  required: "Hourly rate is required",
                                  maxLength: {
                                    value: 4,
                                    message: "Please enter valid hourly rate",
                                  },
                                })}
                                // defaultValue={hourlyRate}
                                type="number"
                                pattern="[0-9]*"
                                className="form-control"
                                placeholder="Enter Hourly Rate"
                              />
                              {errors.hourlyRate && (
                                <p className="text-danger">
                                  {errors.hourlyRate.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb20">
                            <div className="form-style1">
                              <label className="heading-color ff-heading fw500 mb10 fz16">
                                Select Category (Upto {categoryLimit})
                                <span className="required-field">*</span>
                              </label>
                              <div className="bootselect-multiselect">
                                <select
                                  className="form-control"
                                  onChange={handleChange}
                                  disabled={
                                    selectedOptions.length === categoryLimit
                                  }
                                  value=""
                                  required
                                >
                                  <option value="" disabled>
                                    Choose the categories you have experience
                                    in.
                                  </option>
                                  {categories
                                    .filter(
                                      (category) =>
                                        !selectedOptions.includes(category)
                                    )
                                    .map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb10">
                              <div className="skill-tags border-black d-flex flex-wrap">
                                {selectedOptions?.map((option, index) => (
                                  <div
                                    className="position-relative"
                                    style={{
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}
                                    key={index}
                                  >
                                    <span className="tag position-relative">
                                      {option}
                                      <i
                                        className="fa-duotone fa-circle-xmark fa-xl position-absolute top-0 end-0 mt-1 mr-1"
                                        onClick={(e) =>
                                          handleCategoryDelete(e, option)
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                          <div className="mb10">
                            <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                              Do you have studio space?
                              <input
                                type="checkbox"
                                id="isStudioSpaceAvailable"
                                {...register('isStudioSpaceAvailable')}
                                defaultChecked={
                                  isStudioSpaceAvailable
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                              Do You Offer Videography?
                              <input
                                type="checkbox"
                                {...register("isVideographyAvailable")}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                              Do You Offer Studio Space?
                              <input
                                type="checkbox"
                                {...register("isStudioSpaceAvailable")}
                                // defaultChecked={isStudioSpaceAvailable}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                              Do You Have Business Insurance?
                              <input
                                type="checkbox"
                                {...register("isBusinessInsured")}
                                disabled={isInsuranceVerified}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                              By checking this box, you give us permission to
                              share your portfolio images on our social media
                              platforms.
                              <input
                                type="checkbox"
                                {...register("shareConsent")}
                                // defaultChecked={shareConsent}
                              />
                              {errors.shareConsent && (
                                <p className="text-danger">
                                  {errors.shareConsent.message}
                                </p>
                              )}
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        {watch("isBusinessInsured") && (
                          <div className="col-md-12">
                            <>
                              {isBusinessInsured && (
                                <>
                                  <div className="tag list-inline-item mb-2 mb-xl-0 mr10">
                                    <a
                                      href={businessInsuranceFile}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open(
                                          businessInsuranceFile,
                                          "_blank"
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#ffffff !important",
                                        border: "1px solid black !important",
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-file-arrow-down"
                                        style={{
                                          fontSize: "1.2em",
                                          color: "#70528ef5",
                                        }}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          fontSize: "15px",
                                          color: "#70528ef5",
                                        }}
                                      >
                                        Uploaded Document
                                      </span>
                                    </a>
                                  </div>
                                  <i
                                    className="far fa-badge-check"
                                    style={{
                                      fontSize: "1.6em",
                                      marginTop: "0px",
                                      color: isInsuranceVerified
                                        ? "#5BBB7B"
                                        : "",
                                    }}
                                  />
                                  {!isInsuranceVerified &&
                                    businessInsuranceFile && (
                                      <p className="fz14">
                                        <strong style={{ color: "red" }}>
                                          Note:
                                        </strong>{" "}
                                        Thank you for submitting your business
                                        insurance. Our team will review your
                                        submission, and you will receive an
                                        update at your registered email address.
                                        Once your business insurance is
                                        verified, you'll receive a verification
                                        badge.
                                      </p>
                                    )}
                                </>
                              )}
                              <div
                                style={{
                                  border: "2px dashed #aaa",
                                  padding: "20px",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <input
                                  type="file"
                                  id="uploadFile"
                                  className="d-none"
                                  onChange={(e) =>
                                    handleFileChange(e, "insurance")
                                  }
                                />
                                <label htmlFor="uploadFile">
                                  <i
                                    className="fa-regular fa-cloud-arrow-up"
                                    style={{ fontSize: "2em" }}
                                  />
                                  <p className="fz16">
                                    Drag 'n' drop files here, or click to select
                                    files
                                  </p>
                                  <p className="danger fz16">
                                    Note: Please upload your Proof of Insurance
                                    and after verification you will get verified
                                    batch
                                  </p>
                                </label>
                              </div>
                            </>
                          </div>
                        )}
                      </>
                    ) : null}
                    {userType !== "user" && (
                      <div className="col-md-12 mt10">
                        <div className="mb10">
                          <label className="heading-color ff-heading fw500 mb10 fz16">
                            Introduction
                            <span className="required-field">*</span>
                          </label>
                          <textarea
                            {...register("bio", {
                              required: "Introduction is required",
                              maxLength: {
                                value: 500,
                                message:
                                  "Introduction length should be less than 500 characters",
                              },
                            })}
                            className="fz16"
                            // defaultValue={bio}
                            cols="30"
                            rows="6"
                            placeholder="Tell us about yourself..."
                          />
                          {errors.bio && (
                            <p className="text-danger">{errors.bio.message}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {userType !== "admin" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {profileStatus === "pending for review" &&
                              "Your profile is currently under review, and no changes can be made at this time. Please await further updates regarding your profile status."}
                            {profileStatus === "rejected" &&
                              "We regret to inform you that your profile has been rejected. We encourage you to review the feedback sent to your registered email account, make the necessary adjustments to your profile, and resubmit it for consideration."}
                          </Tooltip>
                        )}
                      >
                        <div
                          className={`col-md${
                            profileStatus === "rejected" ? "-4" : "-3"
                          }`}
                        >
                          <div className="text-start">
                            <Button
                              className="ud-btn btn-thm"
                              type="submit"
                              // onClick={handleNextClick}
                              disabled={profileStatus === "pending for review"}
                            >
                              {profileStatus === "rejected"
                                ? "Submit For Review"
                                : "Save"}
                              <i className="fal fa-arrow-right-long"></i>
                            </Button>
                          </div>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicDetailsTab;
