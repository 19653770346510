import React from 'react';
import SideMenu from '../../shared/SideMenu/SideMenu';
import PhotographerJobs from '../../features/photographer/components/PhotographerJobs/PhotographerJobs';
import { UseIsDesktopOrLaptop } from '../../utils/Responsive/Responsive';
import MobileSideBar from '../../shared/MobileSideBar/MobileSideBar';

const PhotographerJobsPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: '-35px' }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? 'dashboard__main' : ''}
          style={{ marginTop: '50px' }}>
          <div
            className="dashboard__content hover-bgc-color"
            style={{ padding: '26px 15px 20px', height: '900px' }}>
            <div className="row pb40">
              <MobileSideBar />
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area">
                  <h5>My Jobs</h5>
                  <p className="text"></p>
                </div>
              </div>
              <PhotographerJobs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotographerJobsPage;
