import React, { useState, Suspense } from 'react';
import { Status } from '../../../../constants/constant';
import Loader from '../../../../utils/Loader';

const JobsTable = React.lazy(() => import('./JobsListing'));

const ManageJobs = () => {
  const [activeTab, setActiveTab] = useState(Status.OPEN);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            <button
              className={`nav-link ${
                activeTab === Status.OPEN ? 'active fw500' : 'fw500 fs15'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.OPEN)}>
              Active
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.COMPLETED ? 'active fw500 ps-0' : 'fw500'
              }`}
              style={{ fontSize: '15px' }}
              type="button"
              onClick={() => handleTabClick(Status.COMPLETED)}>
              Complete
            </button>

            {/* <button
              className={`nav-link ${
                activeTab === Status.APPROVED ? 'active fw500' : 'fw500'
              }`}
              style={{ fontSize: '17px' }}
              type="button"
              onClick={() => handleTabClick(Status.APPROVED)}>
              Approved
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.REJECTED ? 'active fw500' : 'fw500'
              }`}
              type="button"
              style={{ fontSize: '17px' }}
              onClick={() => handleTabClick(Status.REJECTED)}>
              Rejected
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.ACTIVE ? 'active fw500' : 'fw500'
              }`}
              type="button"
              style={{ fontSize: '17px' }}
              onClick={() => handleTabClick(Status.ACTIVE)}>
              Active
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.INACTIVE ? 'active fw500' : 'fw500'
              }`}
              type="button"
              style={{ fontSize: '17px' }}
              onClick={() => handleTabClick(Status.INACTIVE)}>
              In-Active
            </button> */}
            {/* Add more buttons for other tabs if needed */}
          </div>
        </nav>
        <div className="tab-content">
          {activeTab === Status.OPEN && (
            <Suspense fallback={<Loader />}>
              <JobsTable jobStatus={Status.OPEN} />
            </Suspense>
          )}
          {activeTab === Status.COMPLETED && (
            <Suspense fallback={<Loader />}>
              <JobsTable jobStatus={Status.COMPLETED} />
            </Suspense>
          )}
          {/* {activeTab === Status.APPROVED && (
            <Suspense fallback={<Loader />}>
              <JobPostersTable userStatus={Status.APPROVED} />
            </Suspense>
          )}
          {activeTab === Status.REJECTED && (
            <Suspense fallback={<Loader />}>
              <JobPostersTable userStatus={Status.REJECTED} />
            </Suspense>
          )}
          {activeTab === Status.ACTIVE && (
            <Suspense fallback={<Loader />}>
              <JobPostersTable userStatus={Status.ACTIVE} />
            </Suspense>
          )}
          {activeTab === Status.INACTIVE && (
            <Suspense fallback={<Loader />}>
              <JobPostersTable userStatus={Status.INACTIVE} />
            </Suspense>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ManageJobs;
